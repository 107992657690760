import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnitInterface } from 'app/main/unit/unit.component';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TppService {
  private url = environment.url ;

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService
  ) { }

  public FetchData(): Observable<any> {
    //   let token = this.auth.getToken
    //   const headers = new HttpHeaders(
    //     {
    //         Authorization: `Bearer ${token}`,
    //     }
    // );
    return this.httpClient.get(this.url + '/tpp').pipe(map(
      (res: any) => res
    ));
  }

  public PushData(data: any) {
    return this.httpClient.post(`${this.url + '/tpp'}`, data);
  }

  public SignDoc(id: string) {
    return this.httpClient.get(this.url + '/tpp/sign/' + id).pipe(map(
      (res: any) => res
    ));
  }

  

  public DownloadFile(filePath: string): Observable<any> {
    const headers = new HttpHeaders();
   headers.append('Content-Type', 'application/pdf');
    return this.httpClient.get(this.url + filePath, {
      responseType: "blob",
      headers: headers,
    });
  }

  public SignDocument(id: string): Observable<any> {
    return this.httpClient.get(this.url + "/sign/" + id)
  }

  public getByMonth(month: number, year: number): Observable<any> {
    return this.httpClient
        .get(this.url + "/tpp/getbymonth/" + month + "/" + year)
        .pipe(map((res: any) => res));
}

}