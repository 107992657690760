import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserInterface } from 'app/main/userlist/userlist.component';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserlistService {
  private url = environment.url  + "/";

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService
  ) { }


  public FetchData(): Observable<any> {

    return this.httpClient.get(this.url + 'users').pipe(map(
      (res: any) => res
    ));
  }

  public PushData(data: UserInterface): Observable<any> {
    return this.httpClient.post(`${this.url + 'users'}`, data);
  }

  public GetData(id: string): Observable<any> {
    return this.httpClient.get(this.url + 'users/' + id).pipe(map(
      (res: any) => res
    ));
  }

  public PutData(data: any, id:any): Observable<any> {
    return this.httpClient.put(this.url + 'users/' + id, data).pipe(map(
      (res: any) => res
    )); 
  }
  public Destroy(id: string): any {
    return this.httpClient.delete(this.url + 'users/' + id).pipe(map(res => {
      return res;
    }));
  }


}
