<div class="page-layout carded fullwidth">
    <div class="content p-24">
      <div class="h2 mb-24" style="text-align: center;">User List</div>
  
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search any">
      </mat-form-field>
  
      
      <div id='mtable' #mtable>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>Username</th>
                <td mat-cell *matCellDef="let e"> {{e.attributes.username}} </td>
              </ng-container>
  
          <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let e"> {{e.attributes.firstname}} </td>
          </ng-container>
  
          <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let e"> {{e.attributes.lastname}} </td>
          </ng-container>
  
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let e" > {{e.attributes.email}} </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let e" [ngSwitch]="e.attributes.roleid"> 
                <p *ngSwitchCase="0">Admin</p>
                <p *ngSwitchCase="1">Front Office</p>
                <p *ngSwitchCase="2">Sekretariat</p>
                <p *ngSwitchCase="3">PDI</p>
                <p *ngSwitchCase="4">Pengembangan</p>
                <p *ngSwitchCase="5">Mutasi</p>
                <p *ngSwitchCase="6">TU</p>
                <p *ngSwitchCase="7">Sekretaris</p>
             </td>
          </ng-container>
  
  
  
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef>Options</th>
            <td mat-cell *matCellDef="let e">
              <a mat-icon-button (click)="EditData(e.id)">
                <mat-icon>edit</mat-icon>
              </a>
              <button mat-icon-button (click)="Destroy(e.id)"
                onclick="return confirm('Are you sure to delete this record ?')">
                <mat-icon>delete_outline</mat-icon>
              </button>
  
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="mColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: mColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  
  
    <div class="button-container">
      <button mat-fab color="accent" (click)="openDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  
  