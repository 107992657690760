<h1 mat-dialog-title style="text-align: center">Upload Rekap TPP</h1>
<div mat-dialog-content>
  <form
    class="p-24 mr-24"
    fxLayout="column"
    fxLayoutAlign="start"
    fxFlex="1 0 auto"
    [formGroup]="form"
    (ngSubmit)="submit(form)"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field
        appearance="outline"
        style="width: 200px !important; text-align: center"
      >
        <mat-label>Month and Year</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date" />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker
          #dp
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp)"
          panelClass="example-month-picker"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
      <mat-label>Unit Kerja</mat-label>
      <mat-select
        formControlName="unit_id"
        (selectionChange)="unitSelected($event)"
        [(value)]="unitId"
      >
        <mat-option *ngFor="let p of unitList" [value]="p.id">
          {{ p.attributes.unit_name }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="secondary-text">place</mat-icon>
      <mat-error>Unit Kerja is required!</mat-error>
    </mat-form-field>

    <br />

    <mat-label>Dokumen PDF Hasil Generate</mat-label>

    <file-upload
      formControlName="doc_pdf"
      (change)="onSelect($event.value)"
      multiple="true"
      animation="true"
      accept="application/pdf"
    >
    </file-upload>
    <br />

    <mat-label>Dokumen PDF Paraf Pimpinan</mat-label>

    <file-upload
      formControlName="doc_staff"
      (change)="onSelect($event.value)"
      multiple="true"
      animation="true"
      accept="application/pdf"
    >
    </file-upload>
    <br />

    <div fxLayout="row" fxLayoutAlign="left center">
      <button mat-raised-button color="primary">{{ btnText }}</button>
    </div>
  </form>
</div>
<!-- 
<div class="content p-24">
    <mat-divider></mat-divider>
    <table>
        <tr *ngFor="let f of fileList">
            <td>{{f.name}}</td>
            <td> <a mat-icon-button (click)="vPDF(f.url)">
                    <mat-icon matTooltip="view file" style="-webkit-text-fill-color:#03dbfc">cloud_download</mat-icon>
                </a> </td>
        </tr>
    </table>
</div> -->
