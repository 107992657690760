import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PenerimaInterface } from 'app/main/penerima/penerima.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PenerimaService {
  private url = environment.url;

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService
  ) { }

  public FetchData(id: string): Observable<any> {
    //   let token = this.auth.getToken
    //   const headers = new HttpHeaders(
    //     {
    //         Authorization: `Bearer ${token}`,
    //     }
    // );
    return this.httpClient.get(this.url + 'penerima' + '/getbyid/' + id).pipe(map(
      (res: any) => res
    ));
  }

  public PushData(data: PenerimaInterface): Observable<any> {
    return this.httpClient.post(`${this.url + 'penerima'}`, data);
  }

  public GetData(id: string): Observable<any> {
    return this.httpClient.get(this.url + 'penerima/' + id).pipe(map(
      (res: any) => res
    ));
  }

  public PutData(data: any): Observable<any> {
    return this.httpClient.put(this.url + 'penerima/' + data.kodeunit, data).pipe(map(
      (res: any) => res
    ));
  }

  public Destroy(id: string): any {
    return this.httpClient.delete(this.url + 'penerima/' + id).pipe(map(res => {
      return res;
    }));
  }

}
