import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
@Injectable()
export class AuthService {
  isLogin = false;

  roleAs: string = '';
  roleId: number;
  uname: string;

  constructor() { }

  login(token: string, value: number) {
    this.isLogin = true;
    console.log(value)
    value === 0 ? this.roleAs = "admin": this.roleAs ="user";
    console.log(value)
    if(value===9){
      this.roleAs ='kaban'
    }
    if(value===41){
      this.roleAs ='ekin'
    }
    localStorage.setItem('token', token);
    localStorage.setItem('role', value.toString());
    
    localStorage.setItem('STATE', 'true');
    localStorage.setItem('ROLE', this.roleAs);
    return of({ success: this.isLogin, role: this.roleAs });
  }

  logout() {
    this.isLogin = false;
    this.roleAs = '';
    localStorage.setItem('STATE', 'false');
    localStorage.removeItem('ROLE');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('uname');
    return of({ success: this.isLogin, role: '' });
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem('STATE');
    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = localStorage.getItem('ROLE');
    return this.roleAs;
  }

  getRoleId() {
    this.roleId =Number( localStorage.getItem('role'));
    return this.roleId;
  }

  getUname() {
    this.uname = localStorage.getItem('uname');
    return this.uname;
  }

}

