import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisposisiComponent } from './disposisi.component'
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormDialog} from '../disposisi/disposisi.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AuthGuard } from '../auth.guard';

const routes = [
  {
      path: "disposisi",
      component: DisposisiComponent,
      data: { 
        role: ['admin', 'user'] 
      } 
  },
];

@NgModule({
  declarations: [DisposisiComponent, FormDialog],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        BrowserAnimationsModule,
        
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDividerModule,
        MatListModule
  ],
  entryComponents: [FormDialog],

  exports: [
    DisposisiComponent
  ],
  
  // providers: [{ provide: MAT_DIALOG_DATA, useValue: {} },
  // { provide: MatDialogRef, useValue: [] }]


})
export class DisposisiModule { }
