<div class="page-layout carded fullwidth">
    <div class="content p-24">
      <div class="h2 mb-24" style="text-align: center;">Tracking Surat Keluar</div>
  
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search any">
      </mat-form-field>
  
      <button *ngIf="userType === '1'" mat-raised-button color="primary" (click)="print()">Export to PDF</button>
      <br />
      <br />
      <div id='mtable' #mtable>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
        
  
          <ng-container matColumnDef="tglterima">
            <th mat-header-cell *matHeaderCellDef>Tanggal Terima</th>
            <td mat-cell *matCellDef="let e"> {{e.attributes.tglterima}} </td>
          </ng-container>
  
          <ng-container matColumnDef="penerima">
            <th mat-header-cell *matHeaderCellDef>Penerima</th>
            <td mat-cell *matCellDef="let e"> {{e.attributes.penerima}} </td>
          </ng-container>

          <ng-container matColumnDef="tipe">
            <th mat-header-cell *matHeaderCellDef>Tipe</th>
            <td mat-cell *matCellDef="let e"  [ngSwitch]="e.attributes.tipe"> 
              <p *ngSwitchCase="1">Paraf</p>
              <p *ngSwitchCase="2">Selesai</p>
            </td>
          </ng-container>
  
  
  
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef>Options</th>
            <td mat-cell *matCellDef="let e">
              <a mat-icon-button (click)="EditData(e.id)">
                <mat-icon>edit</mat-icon>
              </a>
              <button mat-icon-button (click)="Destroy(e.id)"
                onclick="return confirm('Are you sure to delete this record ?')">
                <mat-icon>delete_outline</mat-icon>
              </button>
  
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="mColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: mColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  
  
    <div class="button-container">
      <button mat-fab color="accent" (click)="openDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  
  