import { FuseNavigation } from "@fuse/types";

// let roleAs = '';
// roleAs=localStorage.getItem('ROLE')



export const navigationtte: FuseNavigation[] = [
    {
        id: "eoffice",
        title: "E-Office",
        type: "group",
        children: [
            
            {
                id: "tpp",
                title: "Rekap TPP",
                type: "item",
                icon: "place",
                url: "/tpp",
            },
        ],
    },
    
]


