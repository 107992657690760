<div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="row">

    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>



        <!-- CONTENT -->
        <div class="content">

            <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                [@animateStagger]="{value:'50'}">

                <!-- WIDGET 1 -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                    fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <!-- Front -->
                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">


                        </div>

                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="light-blue-fg font-size-72 line-height-72">
                                {{projects.suratmasuk_Month}}
                            </div>
                            <div class="h3 secondary-text font-weight-500">SURAT MASUK BULAN INI
                            </div>
                        </div>

                        <div class="p-16 border-top" fxLayout="row" fxLayoutAlign="center center">
                            <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                                <div class="light-red-fg font-size-72 line-height-72">
                                {{projects.disposisi}}
                                    
                                </div>
                                <div class="h3 secondary-text font-weight-500">Disposisi
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- / Front -->



                </fuse-widget>
                <!-- / WIDGET 1 -->

                <!-- WIDGET 2 -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                    fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <!-- Front -->
                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">


                        </div>

                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="red-fg font-size-72 line-height-72">
                                {{projects.suratkeluar_Month}}
                                
                            </div>
                            <div class="h3 secondary-text font-weight-500">SURAT KELUAR BULAN INI
                            </div>
                        </div>

                        <div class="p-16 border-top" fxLayout="row" fxLayoutAlign="center center">
                            <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                                <div class="light-red-fg font-size-72 line-height-72">
                                {{projects.suratkeluar_month_complete}}
                                    
                                </div>
                                <div class="h3 secondary-text font-weight-500">COMPLETE
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- / Front -->



                </fuse-widget>
                <!-- / WIDGET 2 -->

                <!-- WIDGET 3 -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                    fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <!-- Front -->
                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">

                        </div>

                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="orange-fg font-size-72 line-height-72">
                                {{projects.suratkeluar_Day}}
                            </div>
                            <div class="h3 secondary-text font-weight-500">SURAT KELUAR HARI INI
                            </div>
                        </div>

                        <div class="p-16 border-top" fxLayout="row" fxLayoutAlign="center center">
                            <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                                <div class="light-red-fg font-size-72 line-height-72">
                                    {{projects.suratkeluar_day_complete}}
                                </div>
                                <div class="h3 secondary-text font-weight-500">COMPLETE
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- / Front -->



                </fuse-widget>
                <!-- / WIDGET 3 -->

                <!-- WIDGET 4 -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                    fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <!-- Front -->
                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">

                        </div>

                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="blue-grey-fg font-size-72 line-height-72">
                                {{projects.suratkeluar_Month}}
                            </div>
                            <div class="h3 secondary-text font-weight-500">SURAT KELUAR BULAN INI
                            </div>
                        </div>

                        <div class="p-16 border-top" fxLayout="row" fxLayoutAlign="center center">
                            <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                                <div class="light-red-fg font-size-72 line-height-72">
                                    {{projects.suratkeluar_month_complete}}
                                </div>
                                <div class="h3 secondary-text font-weight-500">COMPLETE
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- / Front -->



                </fuse-widget>
                <!-- / WIDGET 4 -->

                <!-- WIDGET 5 -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" fxLayout="row" fxFlex="100"
                    class="widget widget5">


                    

                    <!-- Front -->
                    <div class="fuse-widget-front">

                        <div class="px-16 border-bottom" fxLayout="row wrap" fxLayoutAlign="space-between center">

                            <div fxFlex class="py-16 h3">SUMMARY RESPONSE SURAT MASUK BIDANG</div>
                        </div>


                        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">

                        </div> <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">

                        </div>






                        
                        <div fxLayout="row wrap" fxLayoutAlign="center center" class="pt-8 pb-32">

                            <div fxLayout="row wrap" fxFlex="900" fxLayoutAlign="center center" class="mb-auto">

                                <div class="px72">
                                    <div class="h4 secondary-text" fxLayoutAlign="center center">SEKRETARIAT</div>
                                    <div class="green-fg font-size-72 line-height-72" fxLayoutAlign="center center">
                                        {{projects.disposisiSekretariat}}
                                    </div>
                                </div>



                                <div class="px-72">
                                    <div class="h4 secondary-text" fxLayoutAlign="center center">PDI</div>
                                    <div class="blue-fg font-size-72 line-height-72" fxLayoutAlign="center center">
                                        {{projects.disposisiPDI}}

                                    </div>
                                </div>


                                <div class="px-72">
                                    <div class="h4 secondary-text" fxLayoutAlign="center center">PENGEMBANGAN</div>
                                    <div class="black-fg font-size-72 line-height-72" fxLayoutAlign="center center">
                                        {{projects.disposisiPengembangan}}
                                        
                                    </div>
                                </div>



                                <div class="px72" >
                                    <div class="h4 secondary-text" fxLayoutAlign="center center">MUTASI</div>
                                    <div class="orange-fg font-size-72 line-height-72" fxLayoutAlign="center center">
                                        {{projects.disposisiMutasi}}
                                        
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <!-- / Front -->

                </fuse-widget>
                <!-- / WIDGET 5 -->



            </div>

        </div>
        <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->

    <!-- SIDEBAR -->
    <fuse-sidebar class="sidebar" name="project-dashboard-right-sidebar-1" position="right" lockedOpen="gt-md">

        <!-- SIDEBAR CONTENT -->
        <div class="content">

            <!-- WIDGET GROUP -->
            <div class="widget-group" fxLayout="column" fxFlex="100" [@animateStagger]="{value:'50'}">

                <!-- NOW WIDGET -->
                <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="pb-0">

                    <!-- Front -->
                    <div class="fuse-widget-front">

                        <div class="pl-16 pr-8 py-16" fxLayout="row" fxLayoutAlign="space-between center">

                            <div class="h3">{{dateNow | date: 'EEEE, HH:mm:ss'}}</div>

                            <div>
                                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="more">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #moreMenu="matMenu">
                                    <button mat-menu-item aria-label="Flip widget">
                                        Details
                                    </button>
                                </mat-menu>
                            </div>
                        </div>

                        <div class="p-16 pb-24" fxLayout="column" fxLayoutAlign="center center">
                            <div class="h1 secondary-text">
                                <span>{{dateNow | date: 'MMMM'}}</span>
                            </div>

                            <div class="font-size-72 line-height-88 secondary-text font-weight-400">
                                {{dateNow | date: 'd'}}
                            </div>

                            <div class="h1 secondary-text">
                                <span>{{dateNow | date: 'y'}}</span>
                            </div>
                        </div>

                    </div>
                    <!-- / Front -->

                </fuse-widget>
                <!-- / NOW WIDGET -->

            </div>
            <!-- / WIDGET GROUP -->

        </div>
        <!-- / SIDEBAR CONTENT -->

    </fuse-sidebar>
    <!-- / SIDEBAR -->

</div>