import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/services/auth.service';
import { PenerimaService } from "app/services/penerima.service";
import { UnitService } from 'app/services/unit.service';
import jspdf from 'jspdf';


export interface PenerimaInterface {
  id: number;
  penerima: string;
  tglterima: Date;
  tipe: string

}

let httpStatus: any;
let model: PenerimaInterface[];
let submitText = "SUBMIT";

@Component({
  selector: 'app-penerima',
  templateUrl: './penerima.component.html',
  styleUrls: ['./penerima.component.scss']
})


export class PenerimaComponent implements OnInit {
  mColumns: string[] = ["tglterima", "penerima", "tipe"];

  dataSource = new MatTableDataSource();
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  isLoading = true;

  mdata = [];
  userType: string = '';
  smid: string;
  isDisabled: boolean = true;


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild("mtable") mtable: ElementRef;

  constructor(
      private service: PenerimaService,
      private _fuseProgressBarService: FuseProgressBarService,
      private _snackBar: MatSnackBar,
      private dialog: MatDialog,
      private authService: AuthService,
      private _activatedRoute: ActivatedRoute


  ) {}

  ngOnInit(): void {
    //   this.authService.userType.subscribe(value => this.userType = value);
      this._activatedRoute.queryParams.subscribe((q) => {
        this.smid = q.qsm;
    });
    if (this.smid ? this.Fetchdata(this.smid) : (this.isDisabled = true))
    this.dataSource.paginator = this.paginator;
  }

  

  showProgress(): void {
      this._fuseProgressBarService.setMode("indeterminate");
      this._fuseProgressBarService.show();
  }

  openSnackBar(message: string, action: string): void {
     
      this._snackBar.open(message, action, {
          duration: 8000,
          panelClass: ["red-snackbar"],
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
      });
  }

  Fetchdata(id: string): void {
      this.showProgress();
      this.service.FetchData(id).subscribe(
          (res) => (
              console.log(res),
              this.dataSource.data = res.data,
              this.dataSource.paginator = this.paginator,
              this._fuseProgressBarService.hide()
          ),
          (error) => this.openSnackBar(error.statusText, error.status)
      );
  }

  applyFilter(event: Event): void {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  EditData(id: any): void {
      this.showProgress();
      this.service.GetData(id).subscribe(
          (res) => (
              (model = res),
              (submitText = "UPDATE"),
              this.editDialog(),
              this._fuseProgressBarService.hide()
          ),
          (error) => this.openSnackBar(error.statusText, error.status)
      );
  }

  Destroy(id: any): void {
      this.service.Destroy(id).subscribe(() => {
          this.Fetchdata(this.smid);
          this.openSnackBar("Data Deleted", "Close");
      });
  }

  openDialog(): void {
      const dialogRef = this.dialog.open(FormDialog, {
          height: "550px",
          width: "800px",
          data: {
            id: this.smid,
        },
      });

      dialogRef.afterClosed().subscribe((_result) => {
          submitText = "SUBMIT";
          if (!httpStatus) {
              return;
          }
          if (httpStatus === "201") {
              this.Fetchdata(this.smid);
              this.openSnackBar("OK, Data Submited", httpStatus);
          } else {
              this.openSnackBar("Failed To Submit", httpStatus);
          }
      });
  }

  editDialog(): void {
      const dialogRef = this.dialog.open(FormDialog, {
          height: "650px",
          width: "800px",
          data: model,
      });

      dialogRef.afterClosed().subscribe((_result) => {
          submitText = "SUBMIT";
          if (httpStatus === "200") {
              this.Fetchdata(this.smid);
              this.openSnackBar("OK, Data Updated", httpStatus);
          } else {
              this.openSnackBar("Failed To Update Data", httpStatus);
          }
      });
  }

  print = () => {
      const datapdf = [];
      // tslint:disable-next-line: prefer-for-of
      this.dataSource.data.forEach((d) => {
          datapdf.push([
              d["id"],
              d["attributes"]["unit_name"],
              d["attributes"]["alamat"],
              // d['latitude'],
              // d['longitude'],
          ]);
      });

      // const doc = new jsPDF('l', 'pt');
      const doc = new jspdf("p", "mm", "a4");
      const totalPagesExp = "{total_pages_count_string}";
      const pageContent = function (data) {
          let str = "Page " + (data.doc.internal.pages.length - 1);
          let msg = "generated at " + new Date();

          if (typeof doc.putTotalPages === "function") {
              str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(14);

          doc.text(
              "List Unit Kerja",
              doc.internal.pageSize.getWidth() / 2,
              10,
              { align: "center" }
          );
          doc.setFontSize(8);

          doc.text(
              str,
              data.settings.margin.right + 160,
              doc.internal.pageSize.height - 10
          );

          doc.text(
              msg,
              data.settings.margin.left,
              doc.internal.pageSize.height - 10
          );
      };

      // tslint:disable-next-line: only-arrow-functions

      const autoTable = "autoTable";
      // // doc[autoTable]({ html: '#mtable' });
      doc[autoTable]({
          // head: [['Kode Unit', 'Unit Kerja', 'Alamat', 'Latitude', 'Longitude' ]],
          head: [["Kode Unit", "Unit Kerja", "Alamat"]],
          body: datapdf,
          theme: "grid",
          tableWidth: "auto",
          columnWidth: "auto",
          didDrawPage: pageContent,
          margin: { top: 20 },
          // addPageContent: pageContent,
          styles: {
              halign: "center",
              // overflow: "linebreak",
              // columnWidth: "wrap",
              fontSize: 10,
              cellPadding: 1,
              minCellHeight: 0,
              overflowColumns: "linebreak",
          },
          headStyles: { fillColor: "#e8e4e3", textColor: 0 },
      });

      if (typeof doc.putTotalPages === "function") {
          doc.putTotalPages(totalPagesExp);
      }

      // doc.save('table.pdf');
      // doc.output("dataurlnewwindow");
      doc.output("dataurlnewwindow");
      // doc.output('table.pdf');
  };

  public openPDF(): void {
      const DATA = this.mtable.nativeElement;
      const doc = new jspdf("p", "pt", "a4");
      doc.fromHTML(DATA.innerHTML, 15, 15);
      doc.output("dataurlnewwindow");
  }
}

@Component({
  selector: "fdialog",
  templateUrl: "./fdialog.component.html",
})

// tslint:disable-next-line: component-class-suffix
export class FormDialog implements OnInit {
  form: FormGroup;
  btnText = submitText;
  isChecked: boolean = false;
  unitId: string;
  imgUrl: string = "";
  unitList = [];
  fileList = [];
  files: File[] = [];
  selected = "option2";
  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  isUpdate: boolean = false;

  constructor(
      public dialogRef: MatDialogRef<FormDialog>,
      private fBuilder: FormBuilder,
      private service: PenerimaService,

      @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
      this.form = this.fBuilder.group({
          tglterima: [this.data.tglterima, Validators.required],
          penerima: [this.data.penerima, Validators.required],
          tipe: [this.data.tipe, Validators.required],
          suratkeluar_id: [this.data.id],
      });
  }

  submit(_form: any): void {
      if (_form.valid) {
        const formData = new FormData();
            
        formData.append("suratkeluar_id", this.data.id);

          if (this.btnText === "SUBMIT") {
              this.service.PushData(_form.value).subscribe(
                  () => {
                      httpStatus = "201";
                      this.dialogRef.close();
                  },
                  (error) => {
                      httpStatus = error.status;
                      this.dialogRef.close();
                  }
              );
          } else if (this.btnText === "UPDATE") {
              this.service.PutData(_form.value).subscribe(
                  () => {
                      httpStatus = "200";
                      this.dialogRef.close();
                  },
                  (error) => {
                      httpStatus = error.status;
                      this.dialogRef.close();
                  }
              );
          }
          this.form.reset();
      }
  }
}
