<h1 mat-dialog-title>Add New Unit</h1>
<div mat-dialog-content>
  <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" [formGroup]="form" (ngSubmit)="submit(form)" >

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
      <mat-label>Kode Unit</mat-label>
      <input matInput formControlName="id" [readonly]="btnText === 'UPDATE'" required>
      <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
      <mat-error>Kode Unit is required!</mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

    <mat-form-field appearance="outline" fxFlex="100" class="pl-4" >
      <mat-label>Unit Kerja</mat-label>
      <input matInput formControlName="unit_name"  required>
      <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
      <mat-error>Unit Kerja is required!</mat-error>
    </mat-form-field>

  </div>

  

  <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

    <mat-form-field appearance="outline" fxFlex="100">
      <mat-label>Alamat</mat-label>
      <textarea matInput formControlName="alamat" required>
          </textarea>
      <mat-error>Address is required!</mat-error>
    </mat-form-field>

  </div>
  <div fxLayout="row" fxLayoutAlign="left center">
  <button mat-raised-button color="primary" >{{btnText}}</button>

  </div>
</form>

</div>
<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>