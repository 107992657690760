import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListfileComponent } from "./listfile.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AuthGuard } from '../auth.guard';

const routes = [
  {
      path: "filelist",
      component: ListfileComponent,
      // canActivate: [AuthGuard]
  },
  
];

@NgModule({
  declarations: [ListfileComponent],
  imports: [
      CommonModule,
      RouterModule.forChild(routes),

      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      FileUploadModule,
      BrowserAnimationsModule,
      
      FuseSharedModule,
      MatFormFieldModule,
      MatInputModule,
      MatIconModule,
      MatTooltipModule,
      MatButtonModule,
      MatTableModule,
      MatSnackBarModule,
    MatPaginatorModule,

     
  ],

  exports: [ListfileComponent],
  

  
})
export class ListfileModule { }
