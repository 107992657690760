<div class="page-layout carded fullwidth">
    <div class="content p-24">
      <div class="h2 mb-24" style="text-align: center;">Data File PNS</div>
  
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search any">
      </mat-form-field>
  
      <button mat-raised-button color="primary" (click)="print()">Export to PDF</button>
      <br />
      <br />
      <div id='mtable' #mtable>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
          <ng-container matColumnDef="id"  >
            <th mat-header-cell *matHeaderCellDef [style.display]="'none'">id</th>
            <td mat-cell *matCellDef="let e" [style.display]="'none'"> {{e.id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="nip">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">NIP</th>
            <td mat-cell *matCellDef="let e"> {{e.attributes.nip}} </td>
          </ng-container>
  
          <ng-container matColumnDef="cpns">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">CPNS</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.sk_cpns_no}}] [Tgl: {{e.attributes.sk_cpns_tgl | date: 'dd/MM/yyyy'}} ]
                <a mat-icon-button (click)="openFile(e.attributes.sk_cpns_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.sk_cpns_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>
  
  
          <ng-container matColumnDef="pns">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">PNS</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.sk_pns_no}}] [Tgl: {{e.attributes.sk_pns_tgl | date: 'dd/MM/yyyy'}} ]
                <a mat-icon-button (click)="openFile(e.attributes.sk_pns_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.sk_pns_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>
  
          <ng-container matColumnDef="karpeg">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Karpeg</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.karpeg_no}}] 
                <a mat-icon-button (click)="openFile(e.attributes.karpeg_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.karpeg_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>
  
          <ng-container matColumnDef="ktp">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">KTP</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.ktp_no}}] 
                <a mat-icon-button (click)="openFile(e.attributes.ktp_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.karpeg_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>

          <ng-container matColumnDef="karis_karsu">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Karis</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.kartu_pasangan_no}}] 
                <a mat-icon-button (click)="openFile(e.attributes.kartu_pasangan_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.kartu_pasangan_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>
  
          <ng-container matColumnDef="taspen">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Taspen</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.taspen_no}}] 
                <a mat-icon-button (click)="openFile(e.attributes.taspen_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.taspen_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>
  
          <ng-container matColumnDef="npwp">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">NPWP</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.npwp_no}}] 
                <a mat-icon-button (click)="openFile(e.attributes.npwp_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.npwp_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>

          <ng-container matColumnDef="bpjs">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">BPJS</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
                [ No: {{e.attributes.bpjs_no}}] 
                <a mat-icon-button (click)="openFile(e.attributes.bpjs_image)" >
                  <mat-icon matTooltip="view file" [ngStyle]="{'color': e.attributes.bpjs_image.url != null ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
                </a> </td>
          </ng-container>
  
  
  
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Options</th>
            <td mat-cell *matCellDef="let e">
              <a mat-icon-button (click)="EditData(e.id)">
                <mat-icon>edit</mat-icon>
              </a>
              <button mat-icon-button (click)="Destroy(e.id)"
                onclick="return confirm('Are you sure to delete this record ?')">
                <mat-icon>delete_outline</mat-icon>
              </button>
  
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="mColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: mColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  
  
    <div class="button-container">
      <button mat-fab color="accent" (click)="openDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  
  