<h1 mat-dialog-title style="text-align: center;">Form</h1>
<div mat-dialog-content>
    <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="form"
        (ngSubmit)="submit(form)">

       

       


        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                <mat-label>Tujuan Disposisi</mat-label>
                <mat-select formControlName="tujuan_id" (selectionChange)="unitSelected($event)" [(value)]="unitId">
                    <mat-option *ngFor="let p of unitList" [value]="p.id">
                        {{ p.tujuan }}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">place</mat-icon>
                <mat-error>Tujuan Disposisi is required!</mat-error>
            </mat-form-field>



        </div>

        <file-upload formControlName="file" (change)="onSelect($event.value)" multiple="true" animation="true"
            accept="application/pdf">
        </file-upload>



        <div fxLayout="row" fxLayoutAlign="left center">
            <button mat-raised-button color="primary">{{btnText}}</button>
        </div>


    </form>
</div>

<div class="content p-24">
    <mat-divider></mat-divider>
    <table>
        <tr *ngFor="let f of fileList">
            <td>{{f.name}}</td>
            <td> <a mat-icon-button (click)="vPDF(f.url)">
                    <mat-icon matTooltip="view file" style="-webkit-text-fill-color:#03dbfc">cloud_download</mat-icon>
                </a> </td>
        </tr>
    </table>
</div>