import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import * as shape from 'd3-shape';

import { fuseAnimations } from '@fuse/animations';

import { ProjectDashboardService } from './project.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'project-dashboard',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProjectDashboardComponent implements OnInit {

    projects: any[];
    config: any;
    dateNow = Date.now();

    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {ProjectDashboardService} _projectDashboardService
     */
    // constructor(
    //     private _fuseSidebarService: FuseSidebarService,
    //     private _projectDashboardService: ProjectDashboardService,
    //     private _fuseConfigService: FuseConfigService
    // ) 

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _projectDashboardService: ProjectDashboardService,
    ) {
        // Fully customizable surroundings for this particular component
        this._fuseConfigService.config = {
            colorTheme: 'theme-default-dark',
            layout: {
                style: 'vertical-layout-1',
                width: 'fullwidth',
                navbar: {
                    primaryBackground: 'fuse-navy-700',
                    secondaryBackground: 'fuse-navy-900',
                    folded: true,
                    hidden: true,
                    position: 'left',
                    variant: 'vertical-style-1'
                },
                toolbar: {
                    customBackgroundColor: false,
                    background: 'fuse-white-500',
                    hidden: true,
                    position: 'below-static'
                },
                footer: {
                    customBackgroundColor: true,
                    background: 'fuse-navy-900',
                    hidden: true,
                    position: 'below-fixed'
                },
                sidepanel: {
                    hidden: true,
                    position: 'right'
                }
            },
            customScrollbars: true
        };
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.projects = this._projectDashboardService.projects;
        this._fuseConfigService.config
            .subscribe((config) => {
                this.config = config;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleFold();
    }
}

