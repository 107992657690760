import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "./main/sample/sample.module";
import { UnitModule } from "./main/unit/unit.module";
import { UnitService } from "./services/unit.service";
import { MatFormFieldModule } from "@angular/material/form-field";

import { SuratmasukModule } from "./main/suratmasuk/suratmasuk.module";
import { SuratmasukService } from "./services/suratmasuk.service";
import { DisposisiModule } from "./main/disposisi/disposisi.module";
import { DisposisiService } from "./services/disposisi.service";
import { SuratkeluarModule } from "./main/suratkeluar/suratkeluar.module";
import { SuratkeluarService } from "./services/suratkeluar.service";

import { PnsfileModule } from "./main/pnsfile/pnsfile.module";
import { PenerimaModule } from "./main/penerima/penerima.module";
import { PnsfileService } from "./services/pnsfile.service";
import { ListfileModule } from "./main/listfile/listfile.module";
import { LoginModule } from "./main/login/login.module";
import { UserlistModule } from "./main/userlist/userlist.module";
import { ProjectDashboardModule } from "./main/project/project.module";

import { TppModule } from "./main/tpp/tpp.module";
import { TppService } from "./services/tpp.service";


import { AuthService } from "./services/auth.service";

import { AuthGuard } from "./main/auth.guard";
import { AdminGuard } from "./main/admin.guard";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { PenerimaService } from "./services/penerima.service";
import { PdfViewerModule } from "ng2-pdf-viewer";

const appRoutes: Routes = [
    {
        path: "**",
        redirectTo: "sample",
    },
    {
        path: "project",
        loadChildren: "./main/project/project.module#ProjectDashboardModule",
    },
    {
        path: "unit",
        loadChildren: "./main/unit/unit.module#UnitModule",
    },
    {
        path: "suratmasuk",
        loadChildren: "./main/suratmasuk/suratmasuk.module#SuratmasukModule",
    },
    {
        path: "disposisi",
        loadChildren: "./main/disposisi/disposisi.module#DisposisiModule",
    },
    {
        path: "suratkeluar",
        loadChildren: "./main/suratkeluar/suratkeluar.module#SuratkeluarModule",
    },
    {
        path: "penerima",
        loadChildren: "./main/penerima/penerima.module#PenerimaModule",
    },
    {
        path: "tpp",
        loadChildren: "./main/tpp/tpp.module#TppModule",
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        UnitModule,
        SuratmasukModule,
        DisposisiModule,
        SuratkeluarModule,
        PnsfileModule,
        ListfileModule,
        LoginModule,
        UserlistModule,
        PenerimaModule,
        ProjectDashboardModule,
        TppModule
        

    ],
    bootstrap: [AppComponent],
    providers: [
        UnitService,
        SuratmasukService,
        DisposisiService,
        SuratkeluarService,
        PnsfileService,
        AuthService,
        PenerimaService,
        AuthGuard,
        
        // AdminGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
})
export class AppModule {}
