import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SuratmasukComponent, FormDialog } from "./suratmasuk.component";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { AuthGuard } from '../auth.guard';
import { MatCheckboxModule } from "@angular/material/checkbox";

const routes = [
    {
        path: "suratmasuk",
        component: SuratmasukComponent,
        canActivate: [AuthGuard],
        data: { 
            role: ['admin', 'user'] 
          } 
    },
];

@NgModule({
    declarations: [SuratmasukComponent, FormDialog],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),

        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        BrowserAnimationsModule,
        
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDividerModule,
        MatListModule,
        MatCheckboxModule
    ],
    entryComponents: [FormDialog],

    exports: [SuratmasukComponent],

    // providers: [{ provide: MAT_DIALOG_DATA, useValue: {} },
    // { provide: MatDialogRef, useValue: [] }]
})
export class SuratmasukModule {}
