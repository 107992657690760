import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import { environment } from "environments/environment";

@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    show: boolean = false
    message: string = "LOGIN TO YOUR ACCOUNT"
    url = environment.url + "/";
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private auth: AuthService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            username: ["", Validators.required ],
            password: ["", Validators.required]
        });
    }

    submit(form): void {
        // console.log("ok");
        this.login(`${form.value.username}`, `${form.value.password}`);
    }

    login(username: string, password: string) {
        // let url: string = "http://sipetarung.xyz/authenticate";
        let url: string = this.url + "auth/login";
        

        this.http.post<User>(url, { username, password }).subscribe(
            data => {
              
                    // this.auth.sendToken(data["token"], data["role"])
                    this.auth.login(data["token"], data["role"])
                    localStorage.setItem('uname', data["user"]);
                    this.router.navigate(["/sample"]).then(() => {
                        window.location.reload();
                      });;
                    // this.reloadCurrentRoute
            },
            error => {
                this.show = true
                this.message = "Invalid Username/Password"
                if (error.status === 401) {
                   console.log("401")
                  }
            }
        );
    }

    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
            console.log(currentUrl);
        });
      }

    
}

export interface User {
    email: string;
    password: string;
}
