import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnitInterface } from 'app/main/unit/unit.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  private url = environment.url + "/";

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService
  ) { }

  public FetchData(): Observable<any> {
    //   let token = this.auth.getToken
    //   const headers = new HttpHeaders(
    //     {
    //         Authorization: `Bearer ${token}`,
    //     }
    // );
    return this.httpClient.get(this.url + 'units').pipe(map(
      (res: any) => res
    ));
  }

  public PushData(data: UnitInterface): Observable<any> {
    return this.httpClient.post(`${this.url + 'units'}`, data);
  }

  public GetData(id: string): Observable<any> {
    return this.httpClient.get(this.url + 'units/' + id).pipe(map(
      (res: any) => res
    ));
  }

  public PutData(data: any): Observable<any> {
    return this.httpClient.put(this.url + 'units/' + data.kodeunit, data).pipe(map(
      (res: any) => res
    ));
  }

  public Destroy(id: string): any {
    return this.httpClient.delete(this.url + 'units/' + id).pipe(map(res => {
      return res;
    }));
  }

}
