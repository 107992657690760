import {
    Component,
    OnInit,
    ViewChild,
    Inject,
    ElementRef,
} from "@angular/core";
import { UnitService } from "app/services/unit.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import jspdf from "jspdf";
import "jspdf-autotable";
import { AuthService } from "app/services/auth.service";

export interface UnitInterface {
    id: number;
    unit_name: string;
    alamat: string;


}

let httpStatus: any;
let model: UnitInterface[];
let submitText = "SUBMIT";

@Component({
    selector: "unit",
    templateUrl: "./unit.component.html",
    styleUrls: ["./unit.component.scss"],
})


export class UnitComponent implements OnInit {
    mColumns: string[] = ["id", "unit_name", "alamat"];

    dataSource = new MatTableDataSource();
    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    isLoading = true;
    unitModel: UnitInterface [];
    mdata = [];
    userType: string = '';


    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild("mtable") mtable: ElementRef;

    constructor(
        private service: UnitService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private dialog: MatDialog,
        private authService: AuthService

    ) {}

    ngOnInit(): void {
        this.Fetchdata();
        
    }

    

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }

    openSnackBar(message: string, action: string): void {
       
        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    Fetchdata(): void {
        this.showProgress();
        this.service.FetchData().subscribe(
            (res) => (
                // this.dataSource.data = res.data,

                this.dataSource = new MatTableDataSource(res.data),
        
        
                this.dataSource.filterPredicate = (data:
                    {unit_name: string}, filterValue: string) =>
                    data['attributes']['unit_name'].trim().toLowerCase().indexOf(filterValue) !== -1,
                  
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    
    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        // this.dataSource.filterPredicate = (data: UnitInterface, filter: string): boolean => {
        //     return (
        //       data.unit_name.toLocaleLowerCase().includes(filter)
        //     )
        //   }
    }

    filterByName(filter: string): any {
      const dataFiltered = this.mdata.filter(function(item){
         return item.name.indexOf(filter) > -1
       })
        return dataFiltered;
    }
    EditData(id: any): void {
        this.showProgress();
        this.service.GetData(id).subscribe(
            (res) => (
                (model = res),
                (submitText = "UPDATE"),
                this.editDialog(),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    Destroy(id: any): void {
        this.service.Destroy(id).subscribe(() => {
            this.Fetchdata();
            this.openSnackBar("Data Deleted", "Close");
        });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            height: "450px",
            width: "800px",
            data: {
                id: "",
                unit_name: "",
                alamat: "",
            },
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (!httpStatus) {
                return;
            }
            if (httpStatus === "201") {
                this.Fetchdata();
                this.openSnackBar("OK, Data Submited", httpStatus);
            } else {
                this.openSnackBar("Failed To Submit", httpStatus);
            }
        });
    }

    editDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            height: "550px",
            width: "800px",
            data: model,
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (httpStatus === "200") {
                this.Fetchdata();
                this.openSnackBar("OK, Data Updated", httpStatus);
            } else {
                this.openSnackBar("Failed To Update Data", httpStatus);
            }
        });
    }

    print = () => {
        const datapdf = [];
        // tslint:disable-next-line: prefer-for-of
        this.dataSource.data.forEach((d) => {
            datapdf.push([
                d["id"],
                d["attributes"]["unit_name"],
                d["attributes"]["alamat"],
                // d['latitude'],
                // d['longitude'],
            ]);
        });

        // const doc = new jsPDF('l', 'pt');
        const doc = new jspdf("p", "mm", "a4");
        const totalPagesExp = "{total_pages_count_string}";
        const pageContent = function (data) {
            let str = "Page " + (data.doc.internal.pages.length - 1);
            let msg = "generated at " + new Date();

            if (typeof doc.putTotalPages === "function") {
                str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(14);

            doc.text(
                "List Unit Kerja",
                doc.internal.pageSize.getWidth() / 2,
                10,
                { align: "center" }
            );
            doc.setFontSize(8);

            doc.text(
                str,
                data.settings.margin.right + 160,
                doc.internal.pageSize.height - 10
            );

            doc.text(
                msg,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };

        // tslint:disable-next-line: only-arrow-functions

        const autoTable = "autoTable";
        // // doc[autoTable]({ html: '#mtable' });
        doc[autoTable]({
            // head: [['Kode Unit', 'Unit Kerja', 'Alamat', 'Latitude', 'Longitude' ]],
            head: [["Kode Unit", "Unit Kerja", "Alamat"]],
            body: datapdf,
            theme: "grid",
            tableWidth: "auto",
            columnWidth: "auto",
            didDrawPage: pageContent,
            margin: { top: 20 },
            // addPageContent: pageContent,
            styles: {
                halign: "center",
                // overflow: "linebreak",
                // columnWidth: "wrap",
                fontSize: 10,
                cellPadding: 1,
                minCellHeight: 0,
                overflowColumns: "linebreak",
            },
            headStyles: { fillColor: "#e8e4e3", textColor: 0 },
        });

        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }

        // doc.save('table.pdf');
        // doc.output("dataurlnewwindow");
        doc.output("dataurlnewwindow");
        // doc.output('table.pdf');
    };

    public openPDF(): void {
        const DATA = this.mtable.nativeElement;
        const doc = new jspdf("p", "pt", "a4");
        doc.fromHTML(DATA.innerHTML, 15, 15);
        doc.output("dataurlnewwindow");
    }
}

@Component({
    selector: "fdialog",
    templateUrl: "./fdialog.component.html",
})

// tslint:disable-next-line: component-class-suffix
export class FormDialog implements OnInit {
    form: FormGroup;
    btnText = submitText;
    constructor(
        public dialogRef: MatDialogRef<FormDialog>,
        private fBuilder: FormBuilder,
        private service: UnitService,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.form = this.fBuilder.group({
            id: [this.data.id, Validators.required],
            unit_name: [this.data.unit_name, Validators.required],
            alamat: [this.data.alamat, Validators.required],
        });
    }

    submit(_form: any): void {
        if (_form.valid) {
            if (this.btnText === "SUBMIT") {
                this.service.PushData(_form.value).subscribe(
                    () => {
                        httpStatus = "201";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            } else if (this.btnText === "UPDATE") {
                this.service.PutData(_form.value).subscribe(
                    () => {
                        httpStatus = "200";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            }
            this.form.reset();
        }
    }
}
