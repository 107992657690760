import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/services/auth.service';
import { TppService } from 'app/services/tpp.service';
import { UnitService } from 'app/services/unit.service';
import * as moment from 'moment';
import { Moment } from 'moment';

import { saveAs } from 'file-saver';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-tpp',
  templateUrl: './tpp.component.html',
  styleUrls: ['./tpp.component.scss']
})


export class TppComponent implements OnInit {

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  roleId: number;
  filestatus: boolean = false;
  roleAs: string;
  pdfFilePath = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";


  mColumns: string[] = [
    "periode",
    "unit",
    "doc_pdf",
    "doc_staff",
    "doc_signed",
    "options",
  ];



  date = new FormControl(moment());

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    // if (this.roleId == 1 || this.roleId == 6 || this.roleId == 7) {
    //   // return this.getByMonthFront(this.date.value._d);
    // }
    this.getByMonth(this.date.value._d);

    // this.dataSource.filterPredicate = (data:
    //   {unit_name: string}, filterValue: string) =>
    //   data['attributes']['periode'].trim().toLowerCase().indexOf(filterValue) !== -1

    // this.periode = moment(ctrlValue).format("MMMM YYYY");
  }

  constructor(
    private service: TppService,
    private _authService: AuthService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.Fetchdata()
    this.roleAs = localStorage.getItem('ROLE');

  }

  showProgress(): void {
    this._fuseProgressBarService.setMode("indeterminate");
    this._fuseProgressBarService.show();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 8000,
      panelClass: ["red-snackbar"],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  Fetchdata(): void {
    // if (this.roleId == 1 || this.roleId == 6 || this.roleId == 7) {
    //   return this.Fetchdata_front()
    // }

    this.showProgress();
    this.filestatus = true;
    this.service.FetchData().subscribe(
      (res) => (
        this.dataSource.data = res.data,
        this.dataSource.filterPredicate = (data:
          { unit_name: string }, filterValue: string) =>
          data['attributes']['unit'].trim().toLowerCase().indexOf(filterValue) !== -1,
        this.dataSource.paginator = this.paginator,
        this._fuseProgressBarService.hide()
      ),
      (error) => this.openSnackBar(error.statusText, error.status)
    );
  }


  openFile(f: string): void {
    // return this.pdfDialog();
    window.open(environment.url + f, "_blank");
    return 
    this.showProgress();
    this.service.DownloadFile(f).subscribe(
      (res) => {

        

        // const a = document.createElement('a');
        // a.href = window.URL.createObjectURL(res);
        // a.download = f;
        // a.click();

        // return saveAs(res, f);

        let blob = new Blob([res], { type: 'application/pdf' });
        let pdfUrl = window.URL.createObjectURL(blob);

        var PDF_link = document.createElement('a');
        PDF_link.href = pdfUrl;
        //   TO OPEN PDF ON BROWSER IN NEW TAB
        window.open(pdfUrl);
        //   TO DOWNLOAD PDF TO YOUR COMPUTER
        PDF_link.download = f;
        // PDF_link.click();
        // PDF_link.dispatchEvent(
        //   new MouseEvent("click", {
        //     bubbles: true,
        //     cancelable: true,
        //     view: window
        //   })
        // );




        // var url = window.URL.createObjectURL(res);
        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.setAttribute("style", "display: none");
        // a.href = url;
        // a.target = "_blank";
        // res.filename = f;
        // // a.download = res.filename;
        // a.click();
        // window.URL.revokeObjectURL(url);
        // a.remove(); // remove the element


      },
      (error) => {
        // console.log('download error:', JSON.stringify(error));
        this.openSnackBar(error.statusText, error.status);
        this._fuseProgressBarService.hide();
      },
      () => {
        this._fuseProgressBarService.hide();
      }
    );
  }







  getByMonth(param: any): void {
    this.dataSource.data = [];
    // this.mdata = [];

    let year: any = param.getFullYear();

    let month: any = param.getMonth() + 1;
    this.showProgress();
    this.service.getByMonth(month, year).subscribe(
      (res) => (
        this.dataSource.data = res.data,
        this.dataSource.filterPredicate = (data:
          { unit_name: string }, filterValue: string) =>
          data['attributes']['unit'].trim().toLowerCase().indexOf(filterValue) !== -1,
        this.dataSource.paginator = this.paginator,
        this._fuseProgressBarService.hide()
      ),
      (error) => this.openSnackBar(error.statusText, error.status)
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FormDialog, {
      // minHeight: "calc(100vh - 90px)",
      height: "auto",
      width: "800px",
      data: {
        id: "",
        nosurat: "",
        tglsurat: "",
        perihal: "",
        unit_id: "",
      },
    });

    dialogRef.afterClosed().subscribe((_result) => {
      submitText = "SUBMIT";
      if (!httpStatus) {
        return;
      }
      if (httpStatus === "201") {
        this.Fetchdata();
        this.openSnackBar("OK, Data Submited", httpStatus);
        httpStatus = "";
      } else {
        this.openSnackBar("Failed To Submit", httpStatus);
      }
    });
  }

  pdfDialog(): void {

    const dialogRef = this.dialog.open(pdfDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  sign(doc: string) {
    this.showProgress();
    this.service.SignDoc(doc).subscribe(
      (res) => (
        this.openSnackBar(res.message, res.code),
        this.Fetchdata(),
        this._fuseProgressBarService.hide()
      ),
      (error) => this.openSnackBar(error.statusText, error.status)
    );
  }


}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'pdfdialog.component.html',
})
export class pdfDialog {
  pdfFilePath = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
}

let httpStatus: any;
let submitText = "SUBMIT";

@Component({
  selector: "fdialog",
  templateUrl: "./fdialog.component.html",
})

// tslint:disable-next-line: component-class-suffix
export class FormDialog implements OnInit {
  form: FormGroup;
  btnText = submitText;
  isChecked: boolean = false;
  unitId: string;
  imgUrl: string = "";
  unitList = [];
  fileList = [];
  files: File[] = [];
  selected = "option2";
  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  isUpdate: boolean = false;

  date = new FormControl(moment());

  periode_month: any
  periode_year: any

  constructor(
    public dialogRef: MatDialogRef<FormDialog>,
    private fBuilder: FormBuilder,
    private service: TppService,
    private unitService: UnitService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _snackBar: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.fetchUnit();



    if (this.data.image) {
      if (this.data.image["url"] != null) {
        this.imgUrl = this.data.image["url"].split(/[\\\/]/).pop();
        this.fileList.push({
          name: this.imgUrl,
          url: this.data.image["url"],
        });
      }
    }
    if (this.data.from != "null") {
      this.isChecked = true;
    }

    this.unitId = String(this.data.unit_id);
    this.form = this.fBuilder.group({

      unit_id: [this.unitId],
      doc_pdf: [],
      doc_staff: [],
    });
  }

  ngAfterViewInit(): void { }



  showProgress(): void {
    this._fuseProgressBarService.setMode("indeterminate");
    this._fuseProgressBarService.show();
  }
  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 8000,
      panelClass: ["red-snackbar"],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  async fetchUnit() {
    return this.unitService
      .FetchData()
      .subscribe((res) => (this.unitList = res.data));
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    console.log(this.files);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  unitSelected(e) {
    console.log(e);
    this.unitId = e.value;
    console.log(this.unitId);
  }

  submit(_form: any): void {
    if (_form.valid) {
      const formData = new FormData();

      // for (var i = 0; i < this.file.length; i++) {

      //     formData.append("file[]", this.files[i]);

      //   }
      // console.log(_form.value.file[0])
      formData.append("period_month", this.periode_month);
      formData.append("period_year", this.periode_year);
      formData.append("unit_id", _form.value.unit_id);
      if (_form.value.doc_pdf != null) {
        formData.append("doc_pdf", _form.value.doc_pdf[0]);
      }
      if (_form.value.doc_staff != null) {
        formData.append("doc_staff", _form.value.doc_staff[0]);
      }

      if (this.btnText === "SUBMIT") {
        this.service.PushData(formData).subscribe(
          () => {
            httpStatus = "201";
            this.dialogRef.close();
          },
          (error) => {
            httpStatus = error.status;
            this.dialogRef.close();
          }
        );
      }
      this.form.reset();
    }
  }

  hideUnit(e: MatSlideToggleChange) {
    e.checked === true ? (this.isChecked = true) : (this.isChecked = false);
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    // if(this.roleId == 1 || this.roleId == 6 || this.roleId == 7 ) {
    // return this.getByMonthFront(this.date.value._d);
    //  }
    // this.getByMonth(this.date.value._d);



    this.periode_month = moment(ctrlValue).format("M");
    this.periode_year = moment(ctrlValue).format("YYYY");
  }
}
