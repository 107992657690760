<div class="page-layout carded fullwidth">
    <div class="content p-14">
        <mat-horizontal-stepper linear #stepper>
            <mat-step [stepControl]="firstFormGroup" [editable]="isEditable">
                <ng-template matStepLabel>SK Pengangakatan</ng-template>
                <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    [formGroup]="firstFormGroup"  (ngSubmit)="submit(firstFormGroup)">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>NIP</mat-label>
                            <input matInput formControlName="nip" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>NIP is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Alamat</mat-label>
                            <input matInput formControlName="alamat" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Alamat is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Tanggal SK CPNS</mat-label>
                            <input matInput formControlName="sk_cpns_tgl" [matDatepicker]="picker" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error>Tanggal SK is required!</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor SK CPNS</mat-label>
                            <input matInput formControlName="sk_cpns_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Nomor Surat is required!</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Tanggal SK PNS</mat-label>
                            <input matInput formControlName="sk_pns_tgl" [matDatepicker]="picker1" required>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error>Tanggal SK is required!</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor SK PNS</mat-label>
                            <input matInput formControlName="sk_pns_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Nomor Surat is required!</mat-error>
                        </mat-form-field>
                    </div>



                    <mat-label>SK CPNS</mat-label>

                    <file-upload formControlName="cpns" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>

                    <mat-label>SK PNS</mat-label>

                    <file-upload formControlName="pns" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>

                    <div>
                        <button mat-raised-button color="accent" matStepperNext>Next</button> ||
                        <button mat-raised-button color="primary">{{btnText}}</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
                <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    [formGroup]="secondFormGroup" (ngSubmit)="submitsec(secondFormGroup)">
                    <ng-template matStepLabel>Data Pendukung</ng-template>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor Karpeg</mat-label>
                            <input matInput formControlName="karpeg_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Nomor Karpeg is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-label>Karpeg</mat-label>
                    <file-upload formControlName="karpeg" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor KTP</mat-label>
                            <input matInput formControlName="ktp_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>KTP is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-label>KTP</mat-label>
                    <file-upload formControlName="ktp" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor Kartu Pasangan</mat-label>
                            <input matInput formControlName="kartu_pasangan_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Nomor Kartu Pasangan is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-label>Karis / Karsu</mat-label>
                    <file-upload formControlName="kartu_pasangan" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor Taspen</mat-label>
                            <input matInput formControlName="karpeg_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Nomor Taspen is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-label>Taspen</mat-label>
                    <file-upload formControlName="taspen" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>


                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor NPWP</mat-label>
                            <input matInput formControlName="karpeg_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Nomor NPWP is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-label>NPWP</mat-label>
                    <file-upload formControlName="npwp" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>


                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <mat-form-field appearance="outline" fxFlex="20" class="pl-4">
                            <mat-label>Nomor BPJS</mat-label>
                            <input matInput formControlName="bpjs_no" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>Nomor BPJS is required!</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-label>BPJS</mat-label>
                    <file-upload formControlName="bpjs" style="width:550px !important"
                        (change)="onSelectCpns($event.value)" multiple="false" animation="true"
                        accept="application/pdf">
                    </file-upload>

                    <div>
                        <button mat-raised-button color="warning" matStepperPrevious>Back</button>
                        ||
                        <button mat-raised-button color="primary">{{btnText}}</button>
                        <!-- <button mat-raised-button color="accent" matStepperNext>Next</button> -->
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <p>You are now done.</p>
                <div>
                    <button mat-raised-button color="warning" matStepperPrevious>Back</button> || 
                    <button mat-raised-button color="primary" (click)="stepper.reset()">Submit</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>