<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div>
                <img src="assets/images/logos/kota.png" width="500" height="600">
            </div>
            <!-- <div class="title">BKPPD Kota Kupang</div> -->
        <br>
            <!-- <div >LOGIN TO YOUR ACCOUNT</div> -->
            <!-- <p *ngIf="show"><font color="red">{{message}}</font></p> -->
            <p><font color="red">{{message}}</font></p>
            <form name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="submit(loginForm);">

                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error>
                        Username is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

              

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid">
                    LOGIN
                </button>

            </form>

         

        </div>

    </div>

</div>
