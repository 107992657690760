<div class="page-layout carded fullwidth">
  <div class="content p-24">
    <div class="h2 mb-24" style="text-align: center;">Data Unit Kerja</div>

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search any">
    </mat-form-field>

    <button *ngIf="userType === '1'" mat-raised-button color="primary" (click)="print()">Export to PDF</button>
    <br />
    <br />
    <div id='mtable' #mtable>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Kode Unit</th>
          <td mat-cell *matCellDef="let e"> {{e.id}} </td>
        </ng-container>

        <ng-container matColumnDef="unit_name">
          <th mat-header-cell *matHeaderCellDef>Unit Kerja</th>
          <td mat-cell *matCellDef="let e"> {{e.attributes.unit_name}} </td>
        </ng-container>

        <ng-container matColumnDef="alamat">
          <th mat-header-cell *matHeaderCellDef>Alamat</th>
          <td mat-cell *matCellDef="let e"> {{e.attributes.alamat}} </td>
        </ng-container>


        <!-- <ng-container matColumnDef="latitude">
          <th mat-header-cell *matHeaderCellDef>Latitude</th>
          <td mat-cell *matCellDef="let e"> {{e.latitude}} </td>
        </ng-container>

        <ng-container matColumnDef="longitude">
          <th mat-header-cell *matHeaderCellDef>Longitude</th>
          <td mat-cell *matCellDef="let e"> {{e.longitude}} </td>
        </ng-container> -->

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let e">
            <a mat-icon-button (click)="EditData(e.id)">
              <mat-icon>edit</mat-icon>
            </a>
            <button mat-icon-button (click)="Destroy(e.id)"
              onclick="return confirm('Are you sure to delete this record ?')">
              <mat-icon>delete_outline</mat-icon>
            </button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="mColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: mColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>


  <div class="button-container">
    <button mat-fab color="accent" (click)="openDialog()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>

