import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { PnsfileService } from "app/services/pnsfile.service";

let submitText = "SUBMIT";
let httpStatus: any;

@Component({
    selector: "app-pnsfile",
    templateUrl: "./pnsfile.component.html",
    styleUrls: ["./pnsfile.component.scss"],
})
export class PnsfileComponent implements OnInit {
    isEditable = true;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;

    files: File[] = [];
    btnText = submitText;
    nip: string;
    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";
    constructor(
        private _formBuilder: FormBuilder,
        private _fuseProgressBarService: FuseProgressBarService,
        private service: PnsfileService,
        private _snackBar: MatSnackBar,

    ) {}

    ngOnInit(): void {
        this.firstFormGroup = this._formBuilder.group({
            nip: ["", Validators.required],
            alamat: ["", Validators.required],
            sk_cpns_no: ["", Validators.required],
            sk_cpns_tgl: ["", Validators.required],
            cpns: [],
            sk_pns_no: ["", Validators.required],
            sk_pns_tgl: ["", Validators.required],
            pns: [],
        });

        this.secondFormGroup = this._formBuilder.group({
            karpeg_no: ["", Validators.required],
            karpeg: [],
            ktp_no: ["", Validators.required],
            ktp: [],
            kartu_pasangan_no: ["", Validators.required],
            kartu_pasangan: [],
            taspen_no: ["", Validators.required],
            taspen: [],
            npwp_no: ["", Validators.required],
            npwp: [],
            bpjs_no: ["", Validators.required],
            bpjs: [],
        });
    }
    onSelectCpns(event) {
        this.files.push(...event.addedFiles);
    }

    onSelectPpns(event) {
        this.files.push(...event.addedFiles);
    }

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }

    openSnackBar(message: string, action: string): void {
      this._snackBar.open(message, action, {
          duration: 8000,
          panelClass: ["red-snackbar"],
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
      });
  }

    submit(_form: any): void {
      this.showProgress();

        if (_form.valid) {
            const formData = new FormData();
            this.nip = _form.value.nip;

            formData.append("nip", _form.value.nip);
            formData.append("alamat", _form.value.alamat);
            formData.append("sk_cpns_no", _form.value.sk_cpns_no);
            formData.append("sk_cpns_tgl", _form.value.sk_cpns_tgl);
            formData.append("sk_pns_no", _form.value.sk_pns_no);
            formData.append("sk_pns_tgl", _form.value.sk_pns_tgl);

            if (_form.value.cpns != null) {
                formData.append("sk_cpns_image", _form.value.cpns[0]);
            }

            if (_form.value.pns != null) {
                formData.append("sk_pns_image", _form.value.pns[0]);
            }

            if (this.btnText === "SUBMIT") {

                this.service.PushData(formData).subscribe(
                    () => {
                        httpStatus = "201";
                        this.openSnackBar(httpStatus, "Success Save Data");
                        this._fuseProgressBarService.hide();
                        this.btnText === "UPDATE"
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.openSnackBar(error.statusText, error.status);
                        this._fuseProgressBarService.hide();
                    }
                );
            }
            //  else if (this.btnText === "UPDATE") {
            //     this.service.PutData(formData, this.data.id).subscribe(
            //         () => {
            //             httpStatus = "200";
            //         },
            //         (error) => {
            //             httpStatus = error.status;
            //         }
            //     );
            // }
            // this.form.reset();
        }
    }

    submitsec(_form: any): void {
        if (_form.valid) {
            const formData = new FormData();

            formData.append("karpeg_no", _form.value.karpeg_no);
            formData.append("ktp_no", _form.value.ktp_no);
            formData.append("kartu_pasangan_no", _form.value.kartu_pasangan_no);
            formData.append("taspen_no", _form.value.taspen_no);
            formData.append("npwp_no", _form.value.npwp_no);
            formData.append("bpjs_no", _form.value.bpjs_no);

            if (_form.value.karpeg != null) {
                formData.append("karpeg_image", _form.value.karpeg[0]);
            }

            if (_form.value.ktp != null) {
                formData.append("ktp_image", _form.value.ktp[0]);
            }

            if (_form.value.kartu_pasangan != null) {
                formData.append(
                    "kartu_pasangan_image",
                    _form.value.kartu_pasangan[0]
                );
            }

            if (_form.value.taspen != null) {
                formData.append("taspen_image", _form.value.taspen[0]);
            }

            if (_form.value.npwp != null) {
                formData.append("npwp_image", _form.value.npwp[0]);
            }

            if (_form.value.bpjs != null) {
                formData.append("bpjs_image", _form.value.bpjs[0]);
            }
            // return console.log(this.nip)
            this.nip='12'
            if (this.btnText === "SUBMIT") {
                this.service.PushSecData(formData, this.nip).subscribe(
                    () => {
                        httpStatus = "201";
                        this.openSnackBar(httpStatus, "Success Save Data");
                        this._fuseProgressBarService.hide();
                        this.btnText === "UPDATE"

                    },
                    (error) => {
                        httpStatus = error.status;
                        this.openSnackBar(error.statusText, error.status);
                        this._fuseProgressBarService.hide();
                    }
                );
            }
            //  else if (this.btnText === "UPDATE") {
            //     this.service.PutData(formData, this.data.id).subscribe(
            //         () => {
            //             httpStatus = "200";
            //         },
            //         (error) => {
            //             httpStatus = error.status;
            //         }
            //     );
            // }
            // this.form.reset();
        }
    }
}
