import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class DisposisiService {
    private url = environment.url;

    constructor(private httpClient: HttpClient) {}

    public FetchData(id: string): Observable<any> {
        return this.httpClient
            .get(this.url + "disposisi/getbysurat/" + id)
            .pipe(map((res: any) => res));
    }

    public FetchTujuanDisp(): Observable<any> {
      return this.httpClient
          .get(this.url + "disp/gettujuan/")
          .pipe(map((res: any) => res));
  }

    public PushData(data: any): Observable<any> {
        return this.httpClient.post(`${this.url + "disposisi"}`, data);
    }

    public GetData(id: string): Observable<any> {
        return this.httpClient
            .get(this.url + "disposisi/" + id)
            .pipe(map((res: any) => res));
    }

    public PutData(data: any, id: number): Observable<any> {
        return this.httpClient
            .put(this.url + "disposisi/" + id, data)
            .pipe(map((res: any) => res));
    }

    public Destroy(id: string): any {
        return this.httpClient.delete(this.url + "disposisi/" + id).pipe(
            map((res) => {
                return res;
            })
        );
    }

    public DownloadFile(filePath: string): Observable<any> {
        return this.httpClient.get(this.url + filePath, {
            responseType: "blob",
        });
    }

    public getByDate(tgl: Date): Observable<any> {
        return this.httpClient
            .get(this.url + "disposisi/getbydate/" + tgl)
            .pipe(map((res: any) => res));
    }
}
