<div class="page-layout carded fullwidth">
  <div class="content p-24">
    <div class="h2 mb-24" style="text-align: center">LIST REKAP TPP</div>


   

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search any" />
    </mat-form-field>

    <div>
      <mat-form-field
        appearance="outline"
        style="width: 200px !important; text-align: center"
      >
        <mat-label>Month and Year</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date" />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker
          #dp
          startView="multi-year"
          (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp)"
          panelClass="example-month-picker"
        >
        </mat-datepicker>
      </mat-form-field>

      <!-- <button mat-raised-button color="primary" style="float: right;" (click)="print()">Export to PDF</button> -->
    </div>
    <div *ngIf="dataSource.data.length === 0">
      <div flex="15">
        <p style="font-weight: bold; color: crimson">No Record Found!</p>
      </div>
    </div>

    <div id="mtable" #mtable class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="periode">
          <th mat-header-cell *matHeaderCellDef>Periode</th>
          <td mat-cell *matCellDef="let e">{{ e.attributes.periode }}</td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef>Unit Kerja</th>
          <td mat-cell *matCellDef="let e">{{ e.attributes.unit }}</td>
        </ng-container>

        <ng-container matColumnDef="doc_pdf">
          <th style="text-align: center" mat-header-cell *matHeaderCellDef>
            Doc. PDF Rekap
          </th>
          <!-- <td mat-cell *matCellDef="let e">{{ e.attributes.doc_pdf }}</td> -->
          <td mat-cell *matCellDef="let e" style="text-align: left">
            {{ e.attributes.filename_pdf }}
            <a mat-icon-button (click)="openFile(e.attributes.doc_pdf.url)">
              <mat-icon
                matTooltip="view file"
                [ngStyle]="{
                  color: e.attributes.doc_pdf != null ? '#03dbfc' : 'red'
                }"
              >
                cloud_download</mat-icon
              >
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="doc_staff">
          <th mat-header-cell *matHeaderCellDef>Doc. Paraf</th>
          <td mat-cell *matCellDef="let e" style="text-align: left">
            {{ e.attributes.filename_staff }}
            <a mat-icon-button (click)="openFile(e.attributes.doc_staff.url)">
              <mat-icon
                matTooltip="view file"
                [ngStyle]="{
                  color: e.attributes.doc_staff != null ? '#03dbfc' : 'red'
                }"
              >
                cloud_download</mat-icon
              >
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="doc_signed">
          <th mat-header-cell *matHeaderCellDef>Doc Signed</th>
          <!-- <td mat-cell *matCellDef="let e">{{ e.attributes.doc_signed }}</td> -->
          <td mat-cell *matCellDef="let e" style="text-align: left">
            {{ e.attributes.filename_signed }} 
            <div *ngIf="e.attributes.filename_signed !=''">
            <a mat-icon-button (click)="openFile(e.attributes.doc_signed)">
              <mat-icon
                matTooltip="view file"

                [ngStyle]="{
                  color: e.attributes.filename_signed != '' ? '#03dbfc' : 'red'
                }"
              >
                cloud_download</mat-icon
              >
            </a>
          </div>
          </td>
        </ng-container>

       

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef style="text-align: center">
            Options
          </th>
          <td mat-cell *matCellDef="let e" style="text-align: center">
            <!-- <button mat-raised-button color="warn"  (click)="sign(e.id)" [disabled]="e.attributes.is_signed === true ">Sign</button> -->
            <button mat-raised-button color="warn"  (click)="sign(e.id)" [disabled]="roleAs != 'kaban' || e.attributes.is_signed === true">Sign</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="mColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: mColumns"></tr>
      </table>
      <mat-paginator class="mat-elevation-z8 fullwidth"
        [pageSizeOptions]="[10, 25, 50, 100]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>

  <div class="button-container">
    <button mat-fab color="accent" (click)="openDialog()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

