import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDialog, PenerimaComponent } from './penerima.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthGuard } from '../auth.guard';
import { AdminGuard } from '../admin.guard';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';

const routes = [
  {
    path: 'penerima',
    component: PenerimaComponent,
    canActivate: [AuthGuard],
    data: { 
      expectedRole: ['admin', 'user']
    } 
  }
];


@NgModule({
  declarations: [PenerimaComponent, FormDialog],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    TranslateModule,

    FuseSharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatDividerModule,
    MatDatepickerModule
    // MatProgressSpinnerModule
  ],
  entryComponents: [FormDialog],

  exports: [
    PenerimaComponent
  ],
  
  // providers: [{ provide: MAT_DIALOG_DATA, useValue: {} },
  // { provide: MatDialogRef, useValue: [] }]


})
export class PenerimaModule { }