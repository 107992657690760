import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { PnsfileService } from "app/services/pnsfile.service";

@Component({
    selector: "app-listfile",
    templateUrl: "./listfile.component.html",
    styleUrls: ["./listfile.component.scss"],
})
export class ListfileComponent implements OnInit {
    mColumns: string[] = ["id", "nip", "cpns", "pns", "karpeg", "ktp", "karis_karsu", "taspen", "npwp", "bpjs"];

    dataSource = new MatTableDataSource();
    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";

    isLoading = true;

    mdata = [];
    filestatus: boolean = true;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild("mtable") mtable: ElementRef;
    constructor(
        private service: PnsfileService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.Fetchdata();
    }

    Fetchdata(): void {
        this.showProgress();
        this.service.FetchData().subscribe(
            (res) => (
                (this.dataSource.data = res.data),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }

    openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }
    applyFilter(event: Event): void {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
  }
    openDialog(): void {
      console.log("dilaog")
    }

    print(): void {
      console.log("dilaog")
    }

    openFile(f: string): void {
      this.showProgress();
      this.service.DownloadFile(f["url"]).subscribe(
          (res) => {
              //console.log('start download:', res);
              console.log(res)
              var url = window.URL.createObjectURL(res);
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.setAttribute("style", "display: none");
              a.href = url;
              a.target = "_blank";
              res.filename = f;
              // a.download = res.filename;
              console.log(url)
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove(); // remove the element
          },
          (error) => {
              // console.log('download error:', JSON.stringify(error));
              this.openSnackBar(error.statusText, error.status);
              this._fuseProgressBarService.hide();
          },
          () => {
              this._fuseProgressBarService.hide();
          }
      );
  }
}
