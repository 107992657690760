import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { SuratmasukService } from "app/services/suratmasuk.service";
import { UnitService } from "app/services/unit.service";

import jspdf from "jspdf";
import "jspdf-autotable";
import { map } from "rxjs/operators";
import * as moment from "moment";
import "moment/locale/id";
import { Router } from "@angular/router";
import { Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { SelectionModel } from "@angular/cdk/collections";
import { AuthService } from "app/services/auth.service";

export interface DataInterface {
    id?: number;
    smid?: number;
    nosurat?: string;
    tglsurat?: Date;
    indb?: Date;
    perihal?: string;
    image?: string;
    pengirim?: string;
    filestatus?: boolean;
    ktu?: boolean;
    sek?: boolean;
}

let httpStatus: any;
let model: DataInterface[];
let submitText = "SUBMIT";

@Component({
    selector: "app-suratmasuk",
    templateUrl: "./suratmasuk.component.html",
    styleUrls: ["./suratmasuk.component.scss"],
})
export class SuratmasukComponent implements OnInit {
    mColumns: string[] = [
        "tglsurat",
        "nosurat",
        "tglentry",
        "perihal",
        "unit",
        "image",
        "options",
        "checkItem",
    ];

    dataSource = new MatTableDataSource();
    checked = false;
    ktuCheck = false;
    sekCheck = false;
    isLoading = true;
    public unitId: number;
    mdata: DataInterface[] = [];
    dataInterface: DataInterface[] = [];
    tglFilter: Date;
    periode: String;
    filestatus: boolean = true;
    
    roleId: number;
    isDisabledKTU: boolean = false;
    isDisabledSEK: boolean = false;
    isDisposisi: boolean = false;

    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @ViewChild("mtable") mtable: ElementRef;

    selection = new SelectionModel<DataInterface>(true, []);

    date = new FormControl(moment());

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    chosenMonthHandler(
        normalizedMonth: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.date.setValue(ctrlValue);
        datepicker.close();
        if(this.roleId == 1 || this.roleId == 6 || this.roleId == 7 ) {
        return this.getByMonthFront(this.date.value._d);
         }
        this.getByMonth(this.date.value._d);

        this.periode = moment(ctrlValue).format("MMMM YYYY");
    }

    constructor(
        private service: SuratmasukService,
        private _authService: AuthService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private dialog: MatDialog,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this.periode = moment().format("MMMM YYYY");

        this.dataSource.paginator = this.paginator;

        this.roleId = this._authService.getRoleId();

        this.isDisabledKTU = this.roleId != 0  && this.roleId != 6  ; 
        this.isDisabledSEK = this.roleId != 0  && this.roleId != 7  ; 
        if (this.roleId === 6  || this.roleId === 1)  {
            this.isDisposisi = true
        } else if(this.roleId === 0){
            this.isDisposisi = true
        }
       
       this.Fetchdata()

    }
    ngAfterViewInit() {}

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }

    openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

  
    selectRow($event: { checked: boolean; }, dataSource: { smid: any; }) {
        this.UpdateKTU(dataSource.smid, $event.checked);
    }

    selectRowSek($event, dataSource) {
        this.UpdateSEK(dataSource.smid, $event.checked);
    }

    Fetchdata(): void {
        if(this.roleId == 1 || this.roleId == 6 || this.roleId == 7 ) {
           return this.Fetchdata_front()
        }

        this.showProgress();
        this.mdata = [];
        this.filestatus = true;
        this.service.FetchData().subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let pengirim: string;

                    let SuratMasukRelation = res.included.find(
                        (i) => i.id === e.relationships.suratmasuk.data.id
                    );

                    if (
                        e.relationships.unit != null &&
                        e.relationships.unit.data != null
                    ) {
                        let unit = res.included.find(
                            (i) => i.id === e.relationships.unit.data.id
                        );

                        pengirim = unit.attributes.unit_name;
                    } else {
                        pengirim = SuratMasukRelation.attributes.from;
                    }

                    let NoSuratMasuk = SuratMasukRelation.attributes.nosurat;
                    let TglSuratMasuk = SuratMasukRelation.attributes.tglsurat;
                    let PerihalSuratMasuk =
                        SuratMasukRelation.attributes.perihal;
                    let UrlSuratMasuk = SuratMasukRelation.attributes.image.url;

                    if (UrlSuratMasuk === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        smid: e.attributes.suratmasuk_id,
                        nosurat: NoSuratMasuk,
                        tglsurat: TglSuratMasuk,
                        perihal: PerihalSuratMasuk,
                        image: UrlSuratMasuk,
                        pengirim: pengirim,
                        filestatus: this.filestatus,
                        ktu: e.attributes.ktu,
                        sek: e.attributes.sek,
                        indb: e.attributes.created_at
                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    Fetchdata_front(): void {
        this.showProgress();
        this.mdata = [];
        this.filestatus = true;
        this.service.FetchData().subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let pengirim: string;
                    let ktu: boolean;
                    let sek: boolean;
                    
                    ktu = e.attributes.ktu
                    sek = e.attributes.sek

                    if (
                        e.relationships.disposisi != null  &&
                        e.relationships.disposisi.data != null 
                    )  {
                         ktu = e.attributes.ktu
                        sek = e.attributes.sek
                    } else {
                        pengirim = e.attributes.from;
                        ktu = e.attributes.ktu
                        sek = e.attributes.sek
                    }

                    if (
                        e.relationships.unit != null  &&
                        e.relationships.unit.data != null && e.relationships.unit.data.id != "0"
                    ) {
                        let unit = res.included.find(
                            (i) => i.id === e.relationships.unit.data.id
                        );
                        pengirim = unit.attributes.unit_name;
                    } else {
                        pengirim = e.attributes.from;
                    }

                    let NoSuratMasuk = e.attributes.nosurat;
                    let TglSuratMasuk = e.attributes.tglsurat;
                    let PerihalSuratMasuk =
                        e.attributes.perihal;
                    let UrlSuratMasuk = e.attributes.image.url;

                    if (UrlSuratMasuk === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        smid: e.id,
                        nosurat: NoSuratMasuk,
                        tglsurat: TglSuratMasuk,
                        indb: e.attributes.created_at,
                        perihal: PerihalSuratMasuk,
                        image: UrlSuratMasuk,
                        pengirim: pengirim,
                        filestatus: this.filestatus,
                        ktu: ktu,
                        sek: sek,

                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    getByDate(): void {
        this.dataSource.data = [];
        this.mdata = [];
        this.periode = moment(this.tglFilter["_d"])
            .locale("id")
            .format("dddd Do MMMM YYYY");
        let dateFormat: any =
            this.tglFilter["_d"].getFullYear() +
            "-" +
            (this.tglFilter["_d"].getMonth() + 1) +
            "-" +
            this.tglFilter["_d"].getDate();
        this.showProgress();
        this.service.getByDate(dateFormat).subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let pengirim: string;
                    if (
                        e.relationships.unit.data != null &&
                        e.relationships.unit.data.id != 0
                    ) {
                        let unit = res.included.find(
                            (i) => i.id === e.relationships.unit.data.id
                        );
                        pengirim = unit.attributes.unit_name;
                    } else {
                        pengirim = e.attributes.from;
                    }
                    if (e.attributes.image.url === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat: e.attributes.nosurat,
                        tglsurat: e.attributes.tglsurat,
                        perihal: e.attributes.perihal,
                        image: e.attributes.image.url,
                        pengirim: pengirim,
                        filestatus: this.filestatus,
                        ktu: e.attributes.ktu,
                        sek: e.attributes.sek,
                        indb: e.attributes.created_at

                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    getByMonth(param: any): void {
        this.dataSource.data = [];
        this.mdata = [];

        let year: any = param.getFullYear();

        let month: any = param.getMonth() + 1;

        this.showProgress();
        this.service.getByMonth(month, year).subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let pengirim: string;

                    let SuratMasukRelation = res.included.find(
                        (i) => i.id === e.relationships.suratmasuk.data.id
                    );

                    if (
                        e.relationships.unit != null &&
                        e.relationships.unit.data != null
                    ) {
                        let unit = res.included.find(
                            (i) => i.id === e.relationships.unit.data.id
                        );

                        pengirim = unit.attributes.unit_name;
                    } else {
                        pengirim = SuratMasukRelation.attributes.from;
                    }

                    let NoSuratMasuk = SuratMasukRelation.attributes.nosurat;
                    let TglSuratMasuk = SuratMasukRelation.attributes.tglsurat;
                    let PerihalSuratMasuk =
                        SuratMasukRelation.attributes.perihal;
                    let UrlSuratMasuk = SuratMasukRelation.attributes.image.url;

                    if (UrlSuratMasuk === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat: NoSuratMasuk,
                        tglsurat: TglSuratMasuk,
                        perihal: PerihalSuratMasuk,
                        image: UrlSuratMasuk,
                        pengirim: pengirim,
                        filestatus: this.filestatus,
                        ktu: e.attributes.ktu,
                        sek: e.attributes.sek,
                        indb: e.attributes.created_at

                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    getByMonthFront(param: any): void {
        this.dataSource.data = [];
        this.mdata = [];

        let year: any = param.getFullYear();

        let month: any = param.getMonth() + 1;

        this.showProgress();
        this.service.getByMonth(month, year).subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let pengirim: string;
                    if (
                        e.relationships.unit.data != null &&
                        e.relationships.unit.data.id != 0
                    ) {
                        let unit = res.included.find(
                            (i) => i.id === e.relationships.unit.data.id
                        );
                        pengirim = unit.attributes.unit_name;
                    } else {
                        pengirim = e.attributes.from;
                    }
                    if (e.attributes.image.url === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat: e.attributes.nosurat,
                        tglsurat: e.attributes.tglsurat,
                        perihal: e.attributes.perihal,
                        image: e.attributes.image.url,
                        pengirim: pengirim,
                        filestatus: this.filestatus,
                        ktu: e.attributes.ktu,
                        sek: e.attributes.sek,
                        indb: e.attributes.created_at

                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    EditData(id: any): void {
        this.showProgress();
        this.service
            .GetData(id)
            .pipe(
                map((el) => {
                    model = el;
                    if (el.unit_id != null) {
                        model["pengirim"] = el.unit_id;
                        this.unitId = el.unit_id;
                    } else {
                        model["pengirim"] = el.unit_id;
                    }
                })
            )
            .subscribe(
                () => (
                    (submitText = "UPDATE"),
                    this.editDialog(),
                    this._fuseProgressBarService.hide()
                ),
                (error) => this.openSnackBar(error.statusText, error.status)
            );
    }

    Destroy(id: any): void {
        this.service.Destroy(id).subscribe(() => {
            this.Fetchdata();
            this.openSnackBar("Data Deleted", "Close");
        });
    }

    UpdateKTU(id: any, cstatus: boolean): void {
        this.showProgress();
        this.service.UpdateKTU(id, cstatus).subscribe(
            () => this._fuseProgressBarService.hide(),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    UpdateSEK(id: any, cstatus: boolean): void {
        this.showProgress();
        this.service.UpdateSEK(id, cstatus).subscribe(
            () => this._fuseProgressBarService.hide(),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    addDisposisi(id: any): void {
        this._router.navigate(["/disposisi"], { queryParams: { qsm: id } });
    }

    openFile(f: string): void {
        this.showProgress();
        this.service.DownloadFile(f).subscribe(
            (res) => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.target = "_blank";
                res.filename = f;
                // a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            },
            (error) => {
                // console.log('download error:', JSON.stringify(error));
                this.openSnackBar(error.statusText, error.status);
                this._fuseProgressBarService.hide();
            },
            () => {
                this._fuseProgressBarService.hide();
            }
        );
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            // minHeight: "calc(100vh - 90px)",
            // height: "auto",
            width: "800px",
            data: {
                id: "",
                nosurat: "",
                tglsurat: "",
                perihal: "",
                unit_id: "",
            },
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (!httpStatus) {
                return;
            }
            if (httpStatus === "201") {
                this.Fetchdata();
                this.openSnackBar("OK, Data Submited", httpStatus);
                httpStatus = "";
            } else {
                this.openSnackBar("Failed To Submit", httpStatus);
            }
        });
    }

    editDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            // minHeight: "calc(100vh - 90px)",
            // height: "auto",
            width: "800px",
            data: model,
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (!httpStatus) {
                return;
            }
            if (httpStatus === "200") {
                this.Fetchdata();
                this.openSnackBar("OK, Data Updated", httpStatus);
                httpStatus = "";
            } else {
                this.openSnackBar("Failed To Update", httpStatus);
            }
        });
    }

    print = () => {
        const datapdf = [];
        // tslint:disable-next-line: prefer-for-of
        this.dataSource.data.forEach((d) => {
            datapdf.push([
                d["tglsurat"],
                d["nosurat"],
                d["perihal"],
                d["pengirim"],
            ]);
        });

        // const doc = new jsPDF('l', 'pt');
        const doc = new jspdf("p", "mm", "a4");
        const totalPagesExp = "{total_pages_count_string}";
        const pageContent = function (data) {
            let str = "Page " + (data.doc.internal.pages.length - 1);
            let msg = "generated at " + new Date();

            if (typeof doc.putTotalPages === "function") {
                str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(14);

            doc.text(
                "List Surat Masuk",
                doc.internal.pageSize.getWidth() / 2,
                10,
                {
                    align: "center",
                }
            );
            doc.setFontSize(8);

            doc.text(
                str,
                data.settings.margin.right + 160,
                doc.internal.pageSize.height - 10
            );

            doc.text(
                msg,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };

        const autoTable = "autoTable";

        doc[autoTable]({
            head: [["Tanggal", "Nomor Surat", "Perihal", "Pengirim"]],
            body: datapdf,
            theme: "grid",
            tableWidth: "auto",
            columnWidth: "auto",
            didDrawPage: pageContent,
            margin: {
                top: 20,
            },
            styles: {
                halign: "center",
                fontSize: 10,
                cellPadding: 1,
                minCellHeight: 0,
                overflowColumns: "linebreak",
            },
            headStyles: {
                fillColor: "#e8e4e3",
                textColor: 0,
            },
            // columnStyles: {
            //     0: {columnWidth:20},
            //     1: {columnWidth: 30},
            //     2: {columnWidth: 60},
            //     3: {columnWidth: 50},
            // }
        });

        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }

        doc.output("dataurlnewwindow");
    };

    public openPDF(): void {
        const DATA = this.mtable.nativeElement;
        const doc = new jspdf("p", "pt", "a4");
        doc.fromHTML(DATA.innerHTML, 15, 15);
        doc.output("dataurlnewwindow");
    }
}

@Component({
    selector: "fdialog",
    templateUrl: "./fdialog.component.html",
})

// tslint:disable-next-line: component-class-suffix
export class FormDialog implements OnInit {
    form: FormGroup;
    btnText = submitText;
    isChecked: boolean = false;
    unitId: string;
    imgUrl: string = "";
    unitList = [];
    fileList = [];
    files: File[] = [];
    selected = "option2";
    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";
    isUpdate: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<FormDialog>,
        private fBuilder: FormBuilder,
        private service: SuratmasukService,
        private unitService: UnitService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.fetchUnit();

        if (this.data.image) {
            if (this.data.image["url"] != null) {
                this.imgUrl = this.data.image["url"].split(/[\\\/]/).pop();
                this.fileList.push({
                    name: this.imgUrl,
                    url: this.data.image["url"],
                });
            }
        }
        if (this.data.from != "null") {
            this.isChecked = true;
        }

        this.unitId = String(this.data.unit_id);
        this.form = this.fBuilder.group({
            nosurat: [this.data.nosurat, Validators.required],
            tglsurat: [this.data.tglsurat, Validators.required],
            perihal: [this.data.perihal, Validators.required],
            unit_id: [this.unitId],
            from: [this.data.from],
            // file: [[this.imgUrl]],
            file: [],
        });
    }

    ngAfterViewInit(): void {}

    vPDF(f: string) {
        this.showProgress();
        this.service.DownloadFile(f).subscribe(
            (res) => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.target = "_blank";
                res.filename = f;
                // a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            },
            (error) => {
                // console.log('download error:', JSON.stringify(error));
                this.openSnackBar(error.statusText, error.status);
                this._fuseProgressBarService.hide();
            },
            () => {
                this._fuseProgressBarService.hide();
            }
        );
    }

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }
    openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }
    async fetchUnit() {
        return this.unitService
            .FetchData()
            .subscribe((res) => (this.unitList = res.data));
    }

    onSelect(event) {
        this.files.push(...event.addedFiles);
        console.log(this.files);
    }

    onRemove(event) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }

    unitSelected(e) {
        console.log(e);
        this.unitId = e.value;
        console.log(this.unitId);
    }

    submit(_form: any): void {
        if (_form.valid) {
            const formData = new FormData();

            // for (var i = 0; i < this.file.length; i++) {

            //     formData.append("file[]", this.files[i]);

            //   }
            // console.log(_form.value.file[0])
            formData.append("nosurat", _form.value.nosurat);
            formData.append("tglsurat", _form.value.tglsurat);
            formData.append("perihal", _form.value.perihal);
            formData.append("unit_id", this.unitId);
            formData.append("from", _form.value.from);
            if (_form.value.file != null) {
                formData.append("image", _form.value.file[0]);
            }
            // this.isUploading = true;
            // this.http
            //     .post(`${environment.url}suratmasuk`, formData, {
            //         reportProgress: true,
            //         observe: "events",
            //     })
            //     .subscribe((event) => {
            //         if (event.type === HttpEventType.UploadProgress) {
            //             //  this.progressPercent = Math.round((event.loaded / event.total) * 100);
            //             let percent = Math.round(
            //                 (event.loaded / event.total) * 100
            //             );
            //             console.log(percent);
            //         } else if (event.type === HttpEventType.Response) {
            //             console.log(event);
            //             //  this.isUploading = false;
            //         }
            //     });

            if (this.btnText === "SUBMIT") {
                this.service.PushData(formData).subscribe(
                    () => {
                        httpStatus = "201";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            } else if (this.btnText === "UPDATE") {
                this.service.PutData(formData, this.data.id).subscribe(
                    () => {
                        httpStatus = "200";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            }
            this.form.reset();
        }
    }

    hideUnit(e: MatSlideToggleChange) {
        e.checked === true ? (this.isChecked = true) : (this.isChecked = false);
    }
}
