<h1 mat-dialog-title style="text-align: center;">Form</h1>
<div mat-dialog-content>
    <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="form"
        (ngSubmit)="submit(form)">


        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Tanggal Surat</mat-label>
                <!-- <input matInput formControlName="tglsurat" [matDatepicker]="picker" (dateChange)="cekNo($event)" required> -->
                <input matInput formControlName="tglsurat" [matDatepicker]="picker"  required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error>Tanggal Surat is required!</mat-error>
            </mat-form-field>

        </div>

        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Index Surat</mat-label>
                <input matInput formControlName="nosurat" (ngModelChange)="indexSuratChanged($event)"required>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>Nomor Surat is required!</mat-error>
            </mat-form-field>
        </div> -->

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Nomor Surat</mat-label>
                <!-- <input matInput formControlName="nosurat" [readonly]="btnText === 'UPDATE'" required> -->
                <input matInput formControlName="index" [(value)]="nomor" required>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>Index Surat is required!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Bidang</mat-label>
                <!-- <mat-select formControlName="unit_id" (selectionChange)="unitSelected($event)" [(value)]="unitId"> -->
                <mat-select formControlName="unit_id"  [(value)]="unitId">
                    <mat-option *ngFor="let p of unitList" [value]="p.id">
                        {{ p.name }}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">place</mat-icon>
                <mat-error>Bidang is required!</mat-error>
            </mat-form-field>
        </div>



        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Perihal</mat-label>
                <textarea matInput formControlName="perihal" required>
          </textarea>
                <mat-error>Perihal is required!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Tujuan</mat-label>
                <input matInput formControlName="tujuan" required>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>Tujuan Surat is required!</mat-error>
            </mat-form-field>
        </div>


        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">






        </div>

        <file-upload formControlName="file" (change)="onSelect($event.value)" multiple="true" animation="true"
            accept="application/pdf">
        </file-upload>



        <div fxLayout="row" fxLayoutAlign="left center">
            <button mat-raised-button color="primary">{{btnText}}</button>
        </div>


    </form>
</div>

<div class="content p-24">
    <mat-divider></mat-divider>
    <table>
        <tr *ngFor="let f of fileList">
            <td>{{f.name}}</td>
            <td> <a mat-icon-button (click)="vPDF(f.url)">
                    <mat-icon matTooltip="view file" style="-webkit-text-fill-color:#03dbfc">cloud_download</mat-icon>
                </a> </td>
        </tr>
    </table>
</div>
