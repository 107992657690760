import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserlistComponent, FormDialog } from './userlist.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from '../auth.guard';



const routes = [
  {
    path: 'userlist',
    component: UserlistComponent,
    canActivate: [AuthGuard],
    data: { 
      role: ['admin'] 
    } 
  }
];


@NgModule({
  declarations: [UserlistComponent, FormDialog],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    TranslateModule,

    FuseSharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule
    // MatProgressSpinnerModule
  ],
  entryComponents: [FormDialog],

  exports: [
    UserlistComponent
  ],
  
  // providers: [{ provide: MAT_DIALOG_DATA, useValue: {} },
  // { provide: MatDialogRef, useValue: [] }]


})
export class UserlistModule { }
