import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/services/auth.service';
import { UserlistService } from 'app/services/userlist.service';


export interface UserInterface {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  password: string;
  roleId: number;


}

let httpStatus: any;
let model: UserInterface[];
let submitText = "SUBMIT";

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {

  constructor(
    private service: UserlistService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  mColumns: string[] = ["username", "firstname", "lastname", "email", "role", "options"];

  dataSource = new MatTableDataSource();
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  isLoading = true;

  mdata = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild("mtable") mtable: ElementRef;

  ngOnInit(): void {
    this.Fetchdata()
  }


  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  openDialog(): void {
    const dialogRef = this.dialog.open(FormDialog, {
      height: "450px",
      width: "800px",
      data: {
        id: "",
        unit_name: "",
        alamat: "",
      },
    });



    dialogRef.afterClosed().subscribe((_result) => {
      submitText = "SUBMIT";
      if (!httpStatus) {
        return;
      }
      if (httpStatus === "201") {
        this.Fetchdata();
        this.openSnackBar("OK, Data Submited", httpStatus);
      } else {
        this.openSnackBar("Failed To Submit", httpStatus);
      }
    });


  }

  editDialog(): void {
    const dialogRef = this.dialog.open(FormDialog, {
      height: "550px",
      width: "800px",
      data: model,
    });

    dialogRef.afterClosed().subscribe((_result) => {
      submitText = "SUBMIT";
      if (httpStatus === "200") {
        this.Fetchdata();
        this.openSnackBar("OK, Data Updated", httpStatus);
      } else {
        this.openSnackBar("Failed To Update Data", httpStatus);
      }
    });
  }
  EditData(id: any): void {
    this.showProgress();
    this.service.GetData(id).subscribe(
      (res) => (
        console.log(res),
        (model = res),
        (submitText = "UPDATE"),
        this.editDialog(),
        this._fuseProgressBarService.hide()
      ),
      (error) => this.openSnackBar(error.statusText, error.status)
    );
  }

  showProgress(): void {
    this._fuseProgressBarService.setMode("indeterminate");
    this._fuseProgressBarService.show();
  }
  Destroy(id: any): void {
    this.service.Destroy(id).subscribe(() => {
        this.Fetchdata();
        this.openSnackBar("Data Deleted", "Close");
    });
}



  openSnackBar(message: string, action: string): void {

    this._snackBar.open(message, action, {
      duration: 8000,
      panelClass: ["red-snackbar"],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  Fetchdata(): void {
    this.showProgress();
    this.service.FetchData().subscribe(
      res => (
        console.log(res),
        this.dataSource.data = res.data,
        this.dataSource.paginator = this.paginator,
        this._fuseProgressBarService.hide()
      ),
      (error) => this.openSnackBar(error.statusText, error.status)
    );
  }

}



@Component({
  selector: "fdialog",
  templateUrl: "./fdialog.component.html",
})

// tslint:disable-next-line: component-class-suffix
export class FormDialog implements OnInit {
  form: FormGroup;
  btnText = submitText;
  constructor(
    public dialogRef: MatDialogRef<FormDialog>,
    private fBuilder: FormBuilder,
    private service: UserlistService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.form = this.fBuilder.group({
      // id: [this.data.id],
      firstname: [this.data.firstname, Validators.required],
      lastname: [this.data.lastname, Validators.required],
      username: [this.data.username, Validators.required],
      email: [this.data.email, Validators.required],
      password_digest: [this.data.password_digest, Validators.required],
      roleid: [this.data.roleid, Validators.required],
    });
  }

  submit(_form: any): void {
    if (_form.valid) {
      if (this.btnText === "SUBMIT") {
        this.service.PushData(_form.value).subscribe(
          () => {
            httpStatus = "201";
            this.dialogRef.close();
          },
          (error) => {
            httpStatus = error.status;
            this.dialogRef.close();
          }
        );
      } else if (this.btnText === "UPDATE") {
        this.service.PutData(_form.value, this.data.id).subscribe(
          () => {
            httpStatus = "200";
            this.dialogRef.close();
          },
          (error) => {
            httpStatus = error.status;
            this.dialogRef.close();
          }
        );
      }
      this.form.reset();
    }
  }
}
