<div class="page-layout carded fullwidth">



  <div class="content p-24">
    <div class="h2 mb-24" style="text-align: center;">Surat Masuk <br> Periode : {{periode}}</div>

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search any">
    </mat-form-field>

    <div>
      <mat-form-field appearance="outline" style="width:150px !important; text-align: center ">
        <mat-label>Tanggal</mat-label>
        <input matInput [(ngModel)]="tglFilter" [matDatepicker]="picker" (ngModelChange)="getByDate()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>


      <mat-form-field appearance="outline" style="width:150px !important; text-align: center ">
        <mat-label>Month and Year</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>

      <button mat-raised-button color="primary" style="float: right;" (click)="print()">Export to PDF</button>

    </div>
    <div *ngIf="dataSource.data.length === 0">
      <div flex="15">
        <p style=" font-weight: bold; color: crimson;">No Record Found!</p>
      </div>
    </div>


    <div id='mtable' #mtable>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="tglsurat">
          <th mat-header-cell *matHeaderCellDef>Tanggal</th>
          <td mat-cell *matCellDef="let e"> {{e.tglsurat | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="nosurat">
          <th mat-header-cell *matHeaderCellDef>Nomor Surat</th>
          <td mat-cell *matCellDef="let e"> {{e.nosurat}} </td>
        </ng-container>

        <ng-container matColumnDef="tglentry">
          <th mat-header-cell *matHeaderCellDef>Tanggal Entry</th>
          <td mat-cell *matCellDef="let e"> {{e.indb | date: 'dd/MM/yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="perihal">
          <th mat-header-cell *matHeaderCellDef>Perihal</th>
          <td mat-cell *matCellDef="let e"> {{e.perihal}} </td>
        </ng-container>


        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef>Pengirim</th>
          <td mat-cell *matCellDef="let e"> {{e.pengirim}} </td>
        </ng-container>

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef>File</th>
          <td mat-cell *matCellDef="let e" style="text-align: center;">
            <a mat-icon-button (click)="openFile(e.image)">
              <mat-icon matTooltip="view file" [ngStyle]="{'color': e.filestatus === true ? '#03dbfc' : 'red'}">
                cloud_download</mat-icon>
            </a>
          </td>
        </ng-container>



        <ng-container matColumnDef="options" >
          <th mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let e" style="text-align: center;">


            <a mat-icon-button *ngIf="isDisposisi"  (click)="addDisposisi(e.smid)" style="-webkit-text-fill-color:green">
              <mat-icon matTooltip="disposisi" matTooltipClass="example-tooltip-red" >note</mat-icon>
            </a>
            <a mat-icon-button *ngIf="isDisposisi" (click)="EditData(e.id)" style="-webkit-text-fill-color:#c4b58b">
              <mat-icon matTooltip="edit data">edit</mat-icon>
            </a>
            <button mat-icon-button *ngIf="isDisposisi" (click)="Destroy(e.id)" style="-webkit-text-fill-color:tomato"
              onclick="return confirm('Are you sure to delete this record ?')">
              <mat-icon matTooltip="delete data">delete_outline</mat-icon>
            </button>

          </td>
        </ng-container>

        <ng-container matColumnDef="checkItem">
          <th mat-header-cell *matHeaderCellDef>Check</th>
          <td mat-cell *matCellDef="let e" style="text-align: center;">
            <section style="margin: 12px 0;">
              <mat-checkbox style="margin: 0 12px;" matTooltip="TU checked"    (change)="$event ? selectRow($event, e) : null"
                 [checked]="e.ktu ===  true" [disabled]="isDisabledKTU">
              </mat-checkbox>
              
              <mat-checkbox  style="margin: 0 12px;" matTooltip="Sekretaris checked" (change)="$event ? selectRowSek($event, e) : null"
                 [checked]="e.sek ===  true" [disabled]="isDisabledSEK">
              </mat-checkbox>
            </section>


          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="mColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: mColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>


  <div class="button-container">
    <button mat-fab color="accent" (click)="openDialog()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>