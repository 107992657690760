import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";

import jspdf from "jspdf";
import "jspdf-autotable";
import { map } from "rxjs/operators";
import * as moment from "moment";
import "moment/locale/id";
import { ActivatedRoute, Router } from "@angular/router";
import { DisposisiService } from "app/services/disposisi.service";

export interface DataInterface {
    id: number;
    tujuan_id: string;
    isi_disposisi: string;
    suratmasuk_id: string;
    image: File[];
}

let httpStatus: any;
let model: DataInterface[];
let submitText = "SUBMIT";

@Component({
    selector: "app-disposisi",
    templateUrl: "./disposisi.component.html",
    styleUrls: ["./disposisi.component.scss"],
})
export class DisposisiComponent implements OnInit {
    mColumns: string[] = [
        "tglsurat",
        "nosurat",
        "perihal",
        "tujuan",
        "image",
        "options",
    ];

    dataSource = new MatTableDataSource();

    isLoading = true;
    public unitId: number;
    mdata = [];
    tglFilter: Date;
    periode: String;
    filestatus: boolean = true;
    smid: string;
    isDisabled: boolean = true;
    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @ViewChild("mtable") mtable: ElementRef;

    constructor(
        private service: DisposisiService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private dialog: MatDialog,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.periode = moment().format("MMMM YYYY");
        this._activatedRoute.queryParams.subscribe((q) => {
            this.smid = q.qsm;
        });
        if (this.smid ? this.Fetchdata(this.smid) : (this.isDisabled = true))
            this.dataSource.paginator = this.paginator;
    }

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }

    openSnackBar(message: string, action: string): void {
        this._fuseProgressBarService.hide();

        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    viewSurat(url: string): void {
        this.showProgress();
        this.service.DownloadFile(url).subscribe(
            (res) => {
                var url = window.URL.createObjectURL(res);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.target = "_blank";
                res.filename = url;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            },
            (error) => {
                this.openSnackBar(error.statusText, error.status);
                this._fuseProgressBarService.hide();
            },
            () => {
                this._fuseProgressBarService.hide();
            }
        );
    }

    Fetchdata(id: string): void {
        this.showProgress();
        this.mdata = [];
        this.filestatus = true;
        this.isDisabled = false;
        this.service.FetchData(id).subscribe(
            (res) => (

                res.data.forEach((e: any) => {
                    let nosurat: string;
                    let tglsurat: string;
                    let perihal: string;
                    let tujuan: string;
                    let urlSurat: any;

                    if (
                        e.relationships.suratmasuk.data != null &&
                        e.relationships.suratmasuk.data.id != "0"
                    ) {
                        let suratmasuk = res.included.find(
                            (i: { id: any }) =>
                                i.id === e.relationships.suratmasuk.data.id
                        );
                        nosurat = suratmasuk.attributes.nosurat;
                        urlSurat = suratmasuk.attributes.image.url;
                        tglsurat = moment(suratmasuk.attributes.tglsurat)
                            .locale("id")
                            .format("L");
                        perihal = suratmasuk.attributes.perihal;
                    }


                    if (
                        e.attributes.tujuandisposisi_id != null &&
                        e.attributes.tujuandisposisi_id != "0"
                    ) {
                        let tujuandisp = res.included.find(
                            (i) => (i.attributes.id) === e.attributes.tujuandisposisi_id

                        );

                        tujuan = tujuandisp.attributes.tujuan;
                    }
                    if (e.attributes.image.url === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat: nosurat,
                        tglsurat: tglsurat,
                        perihal: perihal,
                        tujuan: tujuan,
                        image: e.attributes.image.url,
                        urlSurat: urlSurat,
                        filestatus: this.filestatus,
                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    getByDate(): void {
        this.dataSource.data = [];
        this.mdata = [];
        this.periode = moment(this.tglFilter["_d"])
            .locale("id")
            .format("dddd Do MMMM YYYY");
        let dateFormat: any =
            this.tglFilter["_d"].getFullYear() +
            "-" +
            (this.tglFilter["_d"].getMonth() + 1) +
            "-" +
            this.tglFilter["_d"].getDate();
        this.showProgress();
        this.service.getByDate(dateFormat).subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let pengirim: string;
                    if (e.relationships.unit.data != null) {
                        let unit = res.included.find(
                            (i) => i.id === e.relationships.unit.data.id
                        );
                        pengirim = unit.attributes.unit_name;
                    } else {
                        pengirim = e.attributes.from;
                    }
                    if (e.attributes.image.url === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat: e.attributes.nosurat,
                        tglsurat: e.attributes.tglsurat,
                        perihal: e.attributes.perihal,
                        image: e.attributes.image.url,
                        pengirim: pengirim,
                        filestatus: this.filestatus,
                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }
    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    EditData(id: any): void {
        this.showProgress();
        this.service
            .GetData(id)
            .pipe(
                map((el) => {
                    model = el;
                })
            )
            .subscribe(
                () => (
                    (submitText = "UPDATE"),
                    this.editDialog(),
                    this._fuseProgressBarService.hide()
                ),
                (error) => this.openSnackBar(error.statusText, error.status)
            );
    }

    Destroy(id: any): void {
        this.service.Destroy(id).subscribe(() => {
            this.Fetchdata(this.smid);
            this.openSnackBar("Data Deleted", "Close");
        });
    }

    addDisposisi(id: any): void {
        console.log(id);
        this._router.navigate(["/disposisi"]);
    }

    openFile(f: string): void {
        this.showProgress();
        this.service.DownloadFile(f).subscribe(
            (res) => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.target = "_blank";
                res.filename = f;
                // a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            },
            (error) => {
                // console.log('download error:', JSON.stringify(error));
                this.openSnackBar(error.statusText, error.status);
                this._fuseProgressBarService.hide();
            },
            () => {
                this._fuseProgressBarService.hide();
            }
        );
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            width: "800px",
            data: {
                id: this.smid,
            },
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (!httpStatus) {
                return;
            }
            if (httpStatus === "201") {
                this.Fetchdata(this.smid);
                this.openSnackBar("OK, Data Submited", httpStatus);
                httpStatus = "";
            } else {
                this.openSnackBar("Failed To Submit", httpStatus);
            }
        });
    }

    editDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            width: "800px",
            data: model,
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (!httpStatus) {
                return;
            }
            if (httpStatus === "200") {
                this.Fetchdata(this.smid);
                this.openSnackBar("OK, Data Updated", httpStatus);
                httpStatus = "";
            } else {
                this.openSnackBar("Failed To Update", httpStatus);
            }
        });
    }

    print = () => {
        const datapdf = [];
        // tslint:disable-next-line: prefer-for-of
        this.dataSource.data.forEach((d) => {
            datapdf.push([
                d["tglsurat"],
                d["nosurat"],
                d["perihal"],
                d["pengirim"],
            ]);
        });

        // const doc = new jsPDF('l', 'pt');
        const doc = new jspdf("p", "mm", "a4");
        const totalPagesExp = "{total_pages_count_string}";
        const pageContent = function (data) {
            let str = "Page " + (data.doc.internal.pages.length - 1);
            let msg = "generated at " + new Date();

            if (typeof doc.putTotalPages === "function") {
                str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(14);

            doc.text(
                "List Surat Masuk",
                doc.internal.pageSize.getWidth() / 2,
                10,
                {
                    align: "center",
                }
            );
            doc.setFontSize(8);

            doc.text(
                str,
                data.settings.margin.right + 160,
                doc.internal.pageSize.height - 10
            );

            doc.text(
                msg,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };

        const autoTable = "autoTable";

        doc[autoTable]({
            head: [["Tanggal", "Nomor Surat", "Perihal", "Pengirim"]],
            body: datapdf,
            theme: "grid",
            tableWidth: "auto",
            columnWidth: "auto",
            didDrawPage: pageContent,
            margin: {
                top: 20,
            },
            styles: {
                halign: "center",
                fontSize: 10,
                cellPadding: 1,
                minCellHeight: 0,
                overflowColumns: "linebreak",
            },
            headStyles: {
                fillColor: "#e8e4e3",
                textColor: 0,
            },
            // columnStyles: {
            //     0: {columnWidth:20},
            //     1: {columnWidth: 30},
            //     2: {columnWidth: 60},
            //     3: {columnWidth: 50},
            // }
        });

        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }

        doc.output("dataurlnewwindow");
    };

    public openPDF(): void {
        const DATA = this.mtable.nativeElement;
        const doc = new jspdf("p", "pt", "a4");
        doc.fromHTML(DATA.innerHTML, 15, 15);
        doc.output("dataurlnewwindow");
    }
}

@Component({
    selector: "fdialog",
    templateUrl: "./fdialog.component.html",
})

// tslint:disable-next-line: component-class-suffix
export class FormDialog implements OnInit {
    form: FormGroup;
    btnText = submitText;
    isChecked: boolean = false;
    unitId: string;
    imgUrl: string = "";
    unitList = [];
    fileList = [];
    files: File[] = [];
    selected = "option2";
    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";
    isUpdate: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<FormDialog>,
        private fBuilder: FormBuilder,
        private service: DisposisiService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.fetchTujuanDisp();

        if (this.data.image) {
            if (this.data.image["url"] != null) {
                this.imgUrl = this.data.image["url"].split(/[\\\/]/).pop();
                this.fileList.push({
                    name: this.imgUrl,
                    url: this.data.image["url"],
                });
            }
        }
        if (this.data.from != null) {
            this.isChecked = true;
        }
        this.unitId = String(this.data.tujuan_id);
        this.form = this.fBuilder.group({
            tujuan_id: [this.unitId],
            file: [],
        });
    }

    vPDF(f: string) {
        this.showProgress();
        this.service.DownloadFile(f).subscribe(
            (res) => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.target = "_blank";
                res.filename = f;
                // a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            },
            (error) => {
                // console.log('download error:', JSON.stringify(error));
                this.openSnackBar(error.statusText, error.status);
                this._fuseProgressBarService.hide();
            },
            () => {
                this._fuseProgressBarService.hide();
            }
        );
    }

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }
    openSnackBar(message: string, action: string): void {
        this._fuseProgressBarService.hide();

        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }
    async fetchTujuanDisp() {
        return this.service
            .FetchTujuanDisp()
            .subscribe((res) => (this.unitList = res));
    }

    onSelect(event) {
        this.files.push(...event.addedFiles);
        console.log(this.files);
    }

    onRemove(event) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }

    unitSelected(e) {
        console.log(e);
        this.unitId = e.value;
        console.log(this.unitId);
    }

    submit(_form: any): void {
        if (_form.valid) {
            const formData = new FormData();

            formData.append("suratmasuk_id", this.data.id);
            formData.append("tujuandisposisi_id", this.unitId);
            if (_form.value.file != null) {
                formData.append("image", _form.value.file[0]);
            }

            if (this.btnText === "SUBMIT") {
                this.service.PushData(formData).subscribe(
                    () => {
                        httpStatus = "201";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            } else if (this.btnText === "UPDATE") {
                this.service.PutData(formData, this.data.id).subscribe(
                    () => {
                        httpStatus = "200";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            }
            this.form.reset();
        }
    }

    hideUnit(e: MatSlideToggleChange) {
        e.checked === true ? (this.isChecked = true) : (this.isChecked = false);
    }
}
