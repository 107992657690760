import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormDialog, pdfDialog, TppComponent } from "./tpp.component";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';

import {MatStepperModule} from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AuthGuard } from '../auth.guard';
import { AdminGuard } from "../admin.guard";
const routes = [
    {
        path: "tpp",
        component: TppComponent,
        canActivate: [AuthGuard],
        data: { 
            expectedRole: ['kaban', 'ekin']
          } ,
    },
    
];

@NgModule({
    declarations: [TppComponent, FormDialog],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        BrowserAnimationsModule,
        
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatDividerModule,
        MatListModule,
    ],
    entryComponents: [FormDialog],

    exports: [TppComponent],
    providers: [{
      provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
    }]

    
})
export class TppModule {}
