import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class SuratkeluarService {
    private url = environment.url + "/";

    constructor(private httpClient: HttpClient) {}

    public FetchData(): Observable<any> {
        return this.httpClient
            .get(this.url + "suratkeluar")
            .pipe(map((res: any) => res));
    }

    public PushData(data: any): Observable<any> {
        return this.httpClient.post(`${this.url + "suratkeluar"}`, data);
    }

    public GetData(id: string): Observable<any> {
        return this.httpClient
            .get(this.url + "suratkeluar/" + id)
            .pipe(map((res: any) => res));
    }

    public PutData(data: any, id: number): Observable<any> {
        return this.httpClient
            .put(this.url + "suratkeluar/" + id, data)
            .pipe(map((res: any) => res));
    }

    public Destroy(id: string): any {
        return this.httpClient.delete(this.url + "suratkeluar/" + id).pipe(
            map((res) => {
                return res;
            })
        );
    }

    public DownloadFile(filePath: string): Observable<any> {
        return this.httpClient.get(this.url + filePath, {
            responseType: "blob",
        });
    }

    public getByDate(tgl: Date): Observable<any> {
        return this.httpClient
            .get(this.url + "suratkeluar/getbydate/" + tgl)
            .pipe(map((res: any) => res));
    }

    public getByMonth(month: number, year: number): Observable<any> {
        return this.httpClient
            .get(this.url + "suratkeluar/getbymonth/" + month + "/" + year)
            .pipe(map((res: any) => res));
    }

    public getNoSurat(tgl: Date): Observable<any> {
        return this.httpClient
            .get(this.url + "suratkeluar/ceknosurat/" + tgl)
            .pipe(map((res: any) => res));
    }
}
