import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { SuratkeluarService } from "app/services/suratkeluar.service";
import { UnitService } from "app/services/unit.service";

import jspdf from "jspdf";
import "jspdf-autotable";
import { map } from "rxjs/operators";
import * as moment from "moment";
import "moment/locale/id";
import { Router } from "@angular/router";
import { Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import {
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
} from "@angular/material/core";

export interface DataInterface {
    id: number;
    nosurat_id: string;
    tglsurat: string;
    perihal: string;
    image: File[];
    index: string;
}

let httpStatus: any;
let model: DataInterface[];
let submitText = "SUBMIT";

export const MY_FORMATS = {
    parse: {
        dateInput: "MM/YYYY",
    },
    display: {
        dateInput: "MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

@Component({
    selector: "app-suratkeluar",
    templateUrl: "./suratkeluar.component.html",
    styleUrls: ["./suratkeluar.component.scss"],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class SuratkeluarComponent implements OnInit {
    mColumns: string[] = [
        "tgl",
        "nosurat",
        "perihal",
        "tujuan",
        "image",
        "options",
    ];

    dataSource = new MatTableDataSource();

    isLoading = true;
    public unitId: number;
    mdata = [];
    tglFilter: Date;
    monthFilter: Date;
    periode: String;
    filestatus: boolean = true;


    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";

    monthList: string[] = [
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
        "X",
        "XI",
        "XII",
    ];

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @ViewChild("mtable") mtable: ElementRef;

    date = new FormControl(moment());

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.date.value;
        ctrlValue.year(normalizedYear.year());
        this.date.setValue(ctrlValue);
    }

    chosenMonthHandler(
        normalizedMonth: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        const ctrlValue = this.date.value;
        ctrlValue.month(normalizedMonth.month());
        this.date.setValue(ctrlValue);
        datepicker.close();
        this.getByMonth(this.date.value._d);
    }

    constructor(
        private service: SuratkeluarService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,
        private dialog: MatDialog,
        private _router: Router
    ) { }

    ngOnInit(): void {
        this.periode = moment().format("MMMM YYYY");

        this.Fetchdata();
        this.dataSource.paginator = this.paginator;
    }
    ngAfterViewInit() { }

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }

    openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    Fetchdata(): void {
        this.showProgress();
        this.mdata = [];
        this.filestatus = true;
        this.service.FetchData().subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let sub: string = "";
                    let m = this.monthList[moment(e.attributes.tgl).month()];
                    if (e.attributes.nosurat.subnomor != null) {
                        sub = e.attributes.nosurat.subnomor;
                    }
                    if (e.attributes.image === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat: e.attributes.numsuratmanual,
                            // "BKKPD." +
                            // e.attributes.index +
                            // "/" +
                            // e.attributes.bidang +
                            // e.attributes.nosurat +
                            // sub +
                            // "/" +
                            // m +
                            // "/" +
                            // moment(e.attributes.tgl).year(),
                        tglsurat: moment(e.attributes.tgl)
                            .locale("id")
                            .format("L"),
                        perihal: e.attributes.perihal,
                        image: '/uploads/suratkeluar/image/'+ e.id + '/'+ e.attributes.image,
                        tujuan: e.attributes.tujuan,
                        filestatus: this.filestatus,
                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    getByDate(): void {
        this.dataSource.data = [];
        this.mdata = [];
        this.periode = moment(this.tglFilter["_d"])
            .locale("id")
            .format("dddd Do MMMM YYYY");
        let dateFormat: any =
            this.tglFilter["_d"].getFullYear() +
            "-" +
            (this.tglFilter["_d"].getMonth() + 1) +
            "-" +
            this.tglFilter["_d"].getDate();
        this.showProgress();
        this.service.getByDate(dateFormat).subscribe(
            (res) => (
                res.data.forEach((e: any) => {
                    let sub: string = "";
                    let m = this.monthList[moment(e.attributes.tgl).month()];
                    if (e.attributes.nosurat.subnomor != null) {
                        sub = e.attributes.nosurat.subnomor;
                    }
                    if (e.attributes.image === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat:  e.attributes.numsuratmanual,
                            // "BKKPD." +
                            // e.attributes.index +
                            // "/" +
                            // e.attributes.bidang +
                            // e.attributes.nosurat +
                            // sub +
                            // "/" +
                            // m +
                            // "/" +
                            // moment(e.attributes.tgl).year(),
                        tglsurat: moment(e.attributes.tgl)
                            .locale("id")
                            .format("L"),
                        perihal: e.attributes.perihal,
                        image: e.attributes.image,
                        tujuan: e.attributes.tujuan,
                        filestatus: this.filestatus,
                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }


    getByMonth(param: any): void {
        this.dataSource.data = [];
        this.mdata = [];

        let year: any =
            param.getFullYear()

        let month: any = param.getMonth() + 1;


        this.showProgress();
        this.service.getByMonth(month, year).subscribe(
            (res) => (

                res.data.forEach((e: any) => {
                    console.log(e);
                    let sub: string = "";
                    let m = this.monthList[moment(e.attributes.tgl).month()];
                    if (e.attributes.nosurat.subnomor != null) {
                        sub = e.attributes.nosurat.subnomor;
                    }
                    if (e.attributes.image === null) {
                        this.filestatus = false;
                    }
                    this.mdata.push({
                        id: e.id,
                        nosurat:  e.attributes.numsuratmanual,
                            // "BKKPD." +
                            // e.attributes.index +
                            // "/" +
                            // e.attributes.bidang +
                            // e.attributes.nosurat +
                            // sub +
                            // "/" +
                            // m +
                            // "/" +
                            // moment(e.attributes.tgl).year(),
                        tglsurat: moment(e.attributes.tgl)
                            .locale("id")
                            .format("L"),
                        perihal: e.attributes.perihal,
                        image: e.attributes.image,
                        tujuan: e.attributes.tujuan,
                        filestatus: this.filestatus,
                    });
                }),
                (this.dataSource.data = this.mdata),
                (this.dataSource.paginator = this.paginator),
                this._fuseProgressBarService.hide()
            ),
            (error) => this.openSnackBar(error.statusText, error.status)
        );
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    EditData(id: any): void {
        this.showProgress();
        this.service
            .GetData(id)
            .pipe(
                map((el) => {
                    model = el.data.attributes;
                })
            )
            .subscribe(
                () => (
                    (submitText = "UPDATE"),
                    this.editDialog(),
                    this._fuseProgressBarService.hide()
                ),
                (error) => this.openSnackBar(error.statusText, error.status)
            );
    }

    Destroy(id: any): void {
        this.service.Destroy(id).subscribe(() => {
            this.Fetchdata();
            this.openSnackBar("Data Deleted", "Close");
        });
    }

    track(id: any): void {
        this._router.navigate(["/penerima"], { queryParams: { qsm: id } });
    }

    openFile(f: string): void {
        this.showProgress();
        this.service.DownloadFile(f).subscribe(
            (res) => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.target = "_blank";
                res.filename = f;
                // a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            },
            (error) => {
                // console.log('download error:', JSON.stringify(error));
                this.openSnackBar(error.statusText, error.status);
                this._fuseProgressBarService.hide();
            },
            () => {
                this._fuseProgressBarService.hide();
            }
        );
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            // minHeight: "calc(100vh - 90px)",
            // height: "auto",
            width: "800px",
            data: {
                id: "",
                nosurat: "",
                tglsurat: "",
                perihal: "",
                unit_id: "",
            },
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (!httpStatus) {
                return;
            }
            if (httpStatus === "201") {
                this.Fetchdata();
                this.openSnackBar("OK, Data Submited", httpStatus);
                httpStatus = "";
            } else {
                this.openSnackBar("Failed To Submit", httpStatus);
            }
        });
    }

    editDialog(): void {
        const dialogRef = this.dialog.open(FormDialog, {
            // minHeight: "calc(100vh - 90px)",
            // height: "auto",
            width: "800px",
            data: model,
        });

        dialogRef.afterClosed().subscribe((_result) => {
            submitText = "SUBMIT";
            if (!httpStatus) {
                return;
            }
            if (httpStatus === "200") {
                this.Fetchdata();
                this.openSnackBar("OK, Data Updated", httpStatus);
                httpStatus = "";
            } else {
                this.openSnackBar("Failed To Update", httpStatus);
            }
        });
    }

    print = () => {
        const datapdf = [];
        // tslint:disable-next-line: prefer-for-of
        this.dataSource.data.forEach((d) => {
            datapdf.push([
                d["tglsurat"],
                d["nosurat"],
                d["perihal"],
                d["pengirim"],
            ]);
        });

        // const doc = new jsPDF('l', 'pt');
        const doc = new jspdf("p", "mm", "a4");
        const totalPagesExp = "{total_pages_count_string}";
        const pageContent = function (data) {
            let str = "Page " + (data.doc.internal.pages.length - 1);
            let msg = "generated at " + new Date();

            if (typeof doc.putTotalPages === "function") {
                str = str + " of " + totalPagesExp;
            }
            doc.setFontSize(14);

            doc.text(
                "List Surat Masuk",
                doc.internal.pageSize.getWidth() / 2,
                10,
                {
                    align: "center",
                }
            );
            doc.setFontSize(8);

            doc.text(
                str,
                data.settings.margin.right + 160,
                doc.internal.pageSize.height - 10
            );

            doc.text(
                msg,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };

        const autoTable = "autoTable";

        doc[autoTable]({
            head: [["Tanggal", "Nomor Surat", "Perihal", "Pengirim"]],
            body: datapdf,
            theme: "grid",
            tableWidth: "auto",
            columnWidth: "auto",
            didDrawPage: pageContent,
            margin: {
                top: 20,
            },
            styles: {
                halign: "center",
                fontSize: 10,
                cellPadding: 1,
                minCellHeight: 0,
                overflowColumns: "linebreak",
            },
            headStyles: {
                fillColor: "#e8e4e3",
                textColor: 0,
            },
            // columnStyles: {
            //     0: {columnWidth:20},
            //     1: {columnWidth: 30},
            //     2: {columnWidth: 60},
            //     3: {columnWidth: 50},
            // }
        });

        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }

        doc.output("dataurlnewwindow");
    };

    public openPDF(): void {
        const DATA = this.mtable.nativeElement;
        const doc = new jspdf("p", "pt", "a4");
        doc.fromHTML(DATA.innerHTML, 15, 15);
        doc.output("dataurlnewwindow");
    }
}

@Component({
    selector: "fdialog",
    templateUrl: "./fdialog.component.html",
})

// tslint:disable-next-line: component-class-suffix
export class FormDialog implements OnInit {
    form: FormGroup;
    btnText = submitText;
    isChecked: boolean = false;
    unitId: string;
    imgUrl: string = "";
    unitList = [];
    fileList = [];
    files: File[] = [];
    selected = "option2";
    horizontalPosition: MatSnackBarHorizontalPosition = "end";
    verticalPosition: MatSnackBarVerticalPosition = "top";
    isUpdate: boolean = false;
    nomorSurat: string;
    nomor: string;
    dt: any;
    index: string;
    bidang: string;

    monthList: string[] = [
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
        "X",
        "XI",
        "XII",
    ];

    constructor(
        public dialogRef: MatDialogRef<FormDialog>,
        private fBuilder: FormBuilder,
        private service: SuratkeluarService,
        private unitService: UnitService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _snackBar: MatSnackBar,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.fetchUnit();
        console.log(this.data)
        if (this.data.image) {
            if (this.data.image != null) {
              // this.imgUrl = this.data.image["url"].split(/[\\\/]/).pop();
                this.fileList.push({
                    name: this.data.image,
                    url: '/uploads/suratkeluar/image/'+ this.data.id + '/'+ this.data.image,
                });
            }
        }
        console.log(this.fileList);

        if (this.data.from != null) {
            this.isChecked = true;
        }
        this.unitId = String(this.data.unit_id);
        this.form = this.fBuilder.group({
            index: [this.data.numsuratmanual, Validators.required],
            // nosurat: [this.data.nosurat.nosurat, Validators.required],
            tglsurat: [this.data.tgl, Validators.required],
            perihal: [this.data.perihal, Validators.required],
            tujuan: [this.data.tujuan, Validators.required],
            unit_id: [this.data.bidang],
            from: [this.data.from],
            // file: [[this.imgUrl]],
            file: [],
        });
    }

    ngAfterViewInit(): void { }

    vPDF(f: string) {
        this.showProgress();
        this.service.DownloadFile(f).subscribe(
            (res) => {
                //console.log('start download:', res);
                var url = window.URL.createObjectURL(res);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.setAttribute("style", "display: none");
                a.href = url;
                a.target = "_blank";
                res.filename = f;
                // a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            },
            (error) => {
                // console.log('download error:', JSON.stringify(error));
                this.openSnackBar(error.statusText, error.status);
                this._fuseProgressBarService.hide();
            },
            () => {
                this._fuseProgressBarService.hide();
            }
        );
    }

    showProgress(): void {
        this._fuseProgressBarService.setMode("indeterminate");
        this._fuseProgressBarService.show();
    }
    openSnackBar(message: string, action: string): void {
        this._snackBar.open(message, action, {
            duration: 8000,
            panelClass: ["red-snackbar"],
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }
    async fetchUnit() {
        return this.unitList.push(
            {
                id: "A",
                name: "Sekretariat",
            },
            {
                id: "B",
                name: "PDI",
            },
            {
                id: "C",
                name: "Pengembangan",
            },
            {
                id: "D",
                name: "Mutasi",
            }
        );
    }

    onSelect(event) {
        this.files.push(...event.addedFiles);
        console.log(this.files);
    }

    onRemove(event) {
        console.log(event);
        this.files.splice(this.files.indexOf(event), 1);
    }

    indexSuratChanged(e) {
        this.index = e;
    }

    unitSelected(e) {
        this.unitId = e.value;

        this.nomor =
            "BKPPD." +
            this.index +
            "/" +
            this.nomorSurat +
            "/" +
            this.unitId +
           "/"+
            this.monthList[moment(this.dt).month()] +
            "/" +
            moment(this.dt).year();
        this.nomor;
        console.log(this.nomor);
    }

    cekNo(e: { value: moment.MomentInput }) {
        this.dt = moment(e.value).format("YYYY-MM-D");
        return this.service
            .getNoSurat(this.dt)
            .subscribe((res) => (this.nomorSurat = res));
    }

    submit(_form: any): void {
        if (_form.valid) {
            const formData = new FormData();


            // formData.append("nosurat", _form.value.nosurat);
            formData.append("numsuratmanual", _form.value.index);
            formData.append("tgl", _form.value.tglsurat);
            formData.append("perihal", _form.value.perihal);
            formData.append("bidang", _form.value.unit_id);
            formData.append("tujuan", _form.value.tujuan);

            if (_form.value.file != null) {
                formData.append("image", _form.value.file[0]);
            }

            if (this.btnText === "SUBMIT") {
                this.service.PushData(formData).subscribe(
                    () => {
                        httpStatus = "201";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            } else if (this.btnText === "UPDATE") {
                this.service.PutData(formData, this.data.id).subscribe(
                    () => {
                        httpStatus = "200";
                        this.dialogRef.close();
                    },
                    (error) => {
                        httpStatus = error.status;
                        this.dialogRef.close();
                    }
                );
            }
            this.form.reset();
        }
    }

    hideUnit(e: MatSlideToggleChange) {
        e.checked === true ? (this.isChecked = true) : (this.isChecked = false);
    }
}
