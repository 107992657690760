<h1 mat-dialog-title>Add New User</h1>
<div mat-dialog-content>
  <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="form"
    (ngSubmit)="submit(form)">


    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" required>
        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        <mat-error>Field is required!</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstname" required>
        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        <mat-error>Field is required!</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastname" required>
        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        <mat-error>Field is required!</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        <mat-error>Field is required!</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Password</mat-label>
        <input matInput formControlName="password_digest" required>
        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        <mat-error>Field is required!</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
      <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Role</mat-label>
        <select formControlName="roleid" matNativeControl required>
          <option value="1">Front Office</option>
          <option value="2">Sekretariat</option>
          <option value="3">PDI</option>
          <option value="4">Pengembangan</option>
          <option value="5">Mutasi</option>
          <option value="6">TU</option>
          <option value="7">Sekretaris</option>
          <option value="0">Admin</option>
        </select>
        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
        <mat-error>Field is required!</mat-error>
      </mat-form-field>
    </div>



    <div fxLayout="row" fxLayoutAlign="left center">
      <button mat-raised-button color="primary">{{btnText}}</button>

    </div>
  </form>

</div>
<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>