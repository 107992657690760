<div class="page-layout carded fullwidth">



    <div class="content p-24">
      <div class="h2 mb-24" style="text-align: center;">Surat Keluar <br> Periode : {{periode}}</div>
  
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search any">
      </mat-form-field>
  
      <div class="example-container">
        <mat-form-field appearance="outline" style="width:150px !important; text-align: center ">
          <mat-label>Tanggal</mat-label>
          <input matInput [(ngModel)]="tglFilter" [matDatepicker]="picker" (ngModelChange)="getByDate()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width:150px !important; text-align: center ">
            <mat-label>Month and Year</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
  
        <button mat-raised-button color="primary" style="float: right;" (click)="print()">Export to PDF</button>
  
      </div>
      <div *ngIf="dataSource.data.length === 0">
        <div flex="15">
          <p style=" font-weight: bold; color: crimson;">No Record Found!</p>
        </div>
      </div>
  
  
      <div id='mtable' #mtable>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
          <ng-container matColumnDef="tgl">
            <th mat-header-cell *matHeaderCellDef>Tanggal</th>
            <td mat-cell *matCellDef="let e"> {{e.tglsurat}} </td>
          </ng-container>
  
          <ng-container matColumnDef="nosurat">
            <th mat-header-cell *matHeaderCellDef>Nomor Surat</th>
            <td mat-cell *matCellDef="let e"> {{e.nosurat}} </td>
          </ng-container>
  
          <ng-container matColumnDef="perihal">
            <th mat-header-cell *matHeaderCellDef>Perihal</th>
            <td mat-cell *matCellDef="let e"> {{e.perihal}} </td>
          </ng-container>
  
  
          <ng-container matColumnDef="tujuan">
            <th mat-header-cell *matHeaderCellDef>Tujuan</th>
            <td mat-cell *matCellDef="let e"> {{e.tujuan}} </td>
          </ng-container>
  
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef>File</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
              <a mat-icon-button (click)="openFile(e.image)" >
                <mat-icon matTooltip="view file" [ngStyle]="{'color': e.filestatus === true ? '#03dbfc' : 'red'}">cloud_download</mat-icon>
              </a> </td>
          </ng-container>
  
  
  
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef>Options</th>
            <td mat-cell *matCellDef="let e" style="text-align: center;">
              <a mat-icon-button (click)="track(e.id)" style="-webkit-text-fill-color:green">
                <mat-icon matTooltip="disposisi" matTooltipClass="example-tooltip-red">note</mat-icon>
              </a>
              <a mat-icon-button (click)="EditData(e.id)" style="-webkit-text-fill-color:#c4b58b">
                <mat-icon matTooltip="edit data">edit</mat-icon>
              </a>
              <button mat-icon-button (click)="Destroy(e.id)" style="-webkit-text-fill-color:tomato"
                onclick="return confirm('Are you sure to delete this record ?')">
                <mat-icon matTooltip="delete data">delete_outline</mat-icon>
              </button>
  
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="mColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: mColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  
  
    <div class="button-container">
      <button mat-fab color="accent" (click)="openDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>