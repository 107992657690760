import { FuseNavigation } from "@fuse/types";


export const navigation: FuseNavigation[] = [
    {
        id: "eoffice",
        title: "E-Office",
        type: "group",
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "item",
                icon: "apps",
                url: "/dashboard",
            },
         
            {
                id: "unit",
                title: "Unit Kerja",
                type: "item",
                icon: "place",
                url: "/unit",
            },
            {
                id: "suratmasuk",
                title: "Surat Masuk",
                type: "item",
                icon: "local_post_office",
                url: "/suratmasuk",
            },
            {
                id: "disposisi",
                title: "Disposisi",
                type: "item",
                icon: "accessibility",
                url: "/disposisi",
            },
            {
                id: "suratkeluar",
                title: "Surat Keluar",
                type: "item",
                icon: "local_post_office",
                url: "/suratkeluar",
            },
            {
                id: "Penerima",
                title: "Track Surat Keluar",
                type: "item",
                icon: "compare_arrows",
                url: "/penerima",
            },
           
        ],
    },
    {
        id: "efile",
        title: "E-File PNS",
        type: "group",
        children: [
            {
                id: "maindata",
                title: "PNS File",
                type: "item",
                icon: "book",
                url: "/pnsfile",

            },
            {
                id: "listdata",
                title: "List PNS File",
                type: "item",
                icon: "bookmarks",
                url: "/filelist",

            },
        ],
    },

    {
        id: "usernav",
        title: "Manage User",
        type: "group",
        children: [
            {
                id: "listUser",
                title: "List User",
                type: "item",
                icon: "user",
                url: "/userlist",

            },

        ],
    },
]


