<h1 mat-dialog-title style="text-align: center;">Form</h1>
<div mat-dialog-content>
    <form class=" p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="form"
        (ngSubmit)="submit(form)">

       
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Tanggal Terima</mat-label>
                <input matInput formControlName="tglterima" [matDatepicker]="picker" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error>Field is required!</mat-error>
            </mat-form-field>

        </div>


        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Penerima</mat-label>
                <textarea matInput formControlName="penerima" required>
          </textarea>
                <mat-error>Field is required!</mat-error>
            </mat-form-field>
        </div>

      


        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
              <mat-label>Tipe</mat-label>
              <select formControlName="tipe" matNativeControl required>
                <option value="1">Paraf</option>
                <option value="3">TTD</option>
                <option value="2">Selesai</option>
              </select>
              <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
              <mat-error>Field is required!</mat-error>
            </mat-form-field>
          </div>


        <div fxLayout="row" fxLayoutAlign="left center">
            <button mat-raised-button color="primary">{{btnText}}</button>
        </div>


    </form>
</div>

<div class="content p-24">
    <mat-divider></mat-divider>
    <table>
        <tr *ngFor="let f of fileList">
            <td>{{f.name}}</td>
            <td> <a mat-icon-button (click)="vPDF(f.url)">
                    <mat-icon matTooltip="view file" style="-webkit-text-fill-color:#03dbfc">cloud_download</mat-icon>
                </a> </td>
        </tr>
    </table>
</div>